import { Link as ReactLink } from 'react-router-dom';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
// import InputAdornment from '@mui/material/InputAdornment';
// import TextField from '@mui/material/TextField';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

// hooks
import useSettings from 'hooks/useSettings';
import useResponsive from 'hooks/useResponsive';
import { useRouter } from 'hooks/use-router';
// config
import { NAV, headerList } from 'config/constant';

import logoIcon from 'assets/img/logo/logo.png';
import { Icon } from '@iconify/react';
import { ASSETS } from 'utils/axios';
// components

// ----------------------------------------------------------------------

type Props = {
    openNav: boolean;
    onCloseNav: VoidFunction;
};

export default function Navbar({ openNav, onCloseNav }: Props) {
    const isDesktop = useResponsive('up', 'lg');
    const { config, onChangeNav } = useSettings();

    const router = useRouter();

    const goRouter = (link: string) => {
        router.push(link);
        if (openNav) {
            onChangeNav();
        }
    };

    const renderContent = (
        <Box
            sx={{
                height: '100%'
            }}
        >
            <Stack direction="row" justifyContent="space-between" sx={{ p: 1 }}>
                <Box sx={{ width: 1, display: 'flex', justifyContent: 'center', py: 1 }}>
                    <ReactLink to="/home">
                        <Box
                            component="img"
                            src={config && config.domain.logo ? ASSETS(config?.domain.logo) : logoIcon}
                            alt="logo"
                            sx={{ maxWidth: 170, maxHeight: 35 }}
                        />
                    </ReactLink>
                </Box>
                {/* <TextField
                    variant="outlined"
                    placeholder="Search markets..."
                    sx={{ borderRadius: 10, '& input': { py: 1 }, '& .MuiInputBase-root': { borderRadius: 10 } }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Icon icon="ic:baseline-search" color="white" width="24" height="24" />
                            </InputAdornment>
                        )
                    }}
                /> */}
                {!isDesktop && (
                    <IconButton sx={{ p: 0 }} onClick={onChangeNav}>
                        <Icon icon="ic:twotone-menu-open" color="#ffab00" width="30" height="30" />
                    </IconButton>
                )}
            </Stack>
            {headerList.map((item: { title: string; icon: string; link: string }, j: number) => (
                <ListItemButton
                    onClick={() => goRouter(item.link)}
                    key={j}
                    sx={{
                        borderBottom: '1px dashed #919eab3d',
                        '&:hover': {
                            bgcolor: (theme) => theme.palette.background.neutral
                        }
                    }}
                >
                    <ListItemIcon>
                        <Box component="img" src={item.icon} alt="sport" width={18} height={18} mx={1} />
                    </ListItemIcon>
                    <ListItemText primary={item.title} key={j} />
                    <Icon icon="ant-design:caret-right-filled" color="white" width="18" height="18" />
                </ListItemButton>
            ))}
        </Box>
    );

    return (
        <Box
            component="nav"
            sx={{
                flexShrink: { lg: 0 },
                width: { lg: NAV.W_DASHBOARD },
                borderRight: '1px dashed #919eab3d'
            }}
        >
            {isDesktop ? (
                <Drawer
                    open={openNav}
                    variant="permanent"
                    PaperProps={{
                        sx: {
                            zIndex: 0,
                            width: NAV.W_DASHBOARD,
                            bgcolor: 'background.paper',
                            borderRightStyle: 'dashed'
                        }
                    }}
                >
                    {renderContent}
                </Drawer>
            ) : (
                <Drawer
                    open={openNav}
                    onClose={onCloseNav}
                    ModalProps={{
                        keepMounted: true
                    }}
                    PaperProps={{
                        sx: {
                            height: '100vh',
                            bgcolor: 'background.paper',
                            borderRight: '1px dashed #919eab3d',
                            width: NAV.W_DASHBOARD
                        }
                    }}
                >
                    {renderContent}
                </Drawer>
            )}
        </Box>
    );
}
