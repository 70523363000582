import { Outlet } from 'react-router-dom';

// @mui
import { Box, BoxProps } from '@mui/material';
// hooks
import useSetting from 'hooks/useSettings';
import useResponsive from 'hooks/useResponsive';

// config
import { NAV } from 'config/constant';

// components
import SportsHeader from 'components/Header/SportsHeader';
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';
import MobileHeader from 'components/Header/MobileHeader';
import Marquee from 'components/marquee';
import MobileMenu from 'components/MobileMenu';

// ----------------------------------------------------------------------

// const SPACING = 8;

export function Main({ children, sx, ...other }: BoxProps) {
    const { navbar } = useSetting();

    const isNavMini = navbar === 'close';

    const isDesktop = useResponsive('up', 'lg');

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                ...(isDesktop && {
                    // px: 2,
                    // py: `${HEADER.H_DASHBOARD_DESKTOP + SPACING}px`,
                    width: `calc(100% - ${NAV.W_DASHBOARD}px)`,
                    ...(isNavMini && {
                        width: `calc(100% - ${NAV.W_DASHBOARD_MINI}px)`
                    })
                }),
                ...sx
            }}
            {...other}
        >
            {children}
        </Box>
    );
}

const SportsLayout = () => {
    const { navbar, onChangeNav } = useSetting();

    const isDesktop = useResponsive('up', 'sm');
    return (
        <>
            <Box
                sx={{
                    display: { lg: 'flex' },
                    minHeight: { lg: 1 }
                }}
            >
                {navbar === 'open' && <Navbar openNav={navbar === 'open'} onCloseNav={onChangeNav} />}
                <Main>
                    {isDesktop ? <SportsHeader /> : <MobileHeader />}
                    <div style={{ minHeight: 'calc(100vh - 44px - 347px)' }}>
                        <Marquee />
                        <Outlet />
                    </div>
                    <Footer />
                </Main>
            </Box>
            <MobileMenu />
        </>
    );
};

export default SportsLayout;
