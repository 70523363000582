import { useCallback } from 'react';
import { Icon } from '@iconify/react';
import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { LoadingButton } from '@mui/lab';

// hook
import useSettings from 'hooks/useSettings';
import { RHFTextField } from 'components/hook-form';
import FormProvider from 'components/hook-form/form-provider';
import useAuth from 'hooks/useAuth';
import { useBoolean } from 'hooks/use-boolean';
import { passwordRegex } from 'utils/utils';
import { updatePassword } from 'utils/apis';

type FormValuesProps = {
    oldpassword: string;
    newpassword: string;
    confirmpassword: string;
    afterSubmit?: string;
};

const ChangePassword = () => {
    const { passwordModal, onChangePasswordModal } = useSettings();
    const { user, logout } = useAuth();

    const submintting = useBoolean();
    const oldShow = useBoolean();
    const passwordShow = useBoolean();
    const confirmShow = useBoolean();

    const LoginSchema = Yup.object().shape({
        oldpassword: Yup.string().required('Current Password is required'),
        newpassword: Yup.string().required('Password is required'),
        confirmpassword: Yup.string().required('Confirm Password is required')
    });

    const defaultValues = {
        oldpassword: '',
        newpassword: '',
        confirmpassword: ''
    };

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(LoginSchema),
        defaultValues
    });

    const {
        reset,
        setError,
        handleSubmit,
        formState: { errors, isSubmitting }
    } = methods;

    const onSubmit = handleSubmit(async (data) => {
        try {
            if (!passwordRegex.test(data.newpassword)) {
                setError('newpassword', {
                    message: 'Password must contain 1 Upper, 1 Lower and one digit.'
                });
                return;
            }

            if (data.newpassword !== data.confirmpassword) {
                setError('confirmpassword', {
                    message: 'Please check again.'
                });
                return;
            }

            if (!user) {
                return;
            }
            submintting.onTrue();
            await updatePassword({
                newpassword: data.newpassword,
                oldpassword: data.oldpassword
            });
            reset();
            submintting.onFalse();
            logout();
            closeModal();
        } catch (error: any) {
            submintting.onFalse();
            const errMessage = typeof error === 'string' ? error : error.message;
            setError('afterSubmit', {
                ...error,
                message: errMessage
            });
        }
    });

    const closeModal = useCallback(() => {
        if (isSubmitting) return;
        onChangePasswordModal(false);
    }, [isSubmitting]);

    return (
        <Dialog
            open={passwordModal}
            onClose={closeModal}
            sx={{
                '& .MuiDialog-paper': {
                    border: '1px dashed #919eab',
                    borderColor: 'info.dark',
                    bgcolor: 'background.default'
                }
            }}
        >
            <FormProvider methods={methods} onSubmit={onSubmit}>
                <DialogTitle>Change Password</DialogTitle>
                <DialogContent sx={{ pb: 3 }}>
                    <Stack spacing={2} sx={{ pt: 1 }}>
                        <RHFTextField
                            name="oldpassword"
                            label="Current Password"
                            type={oldShow.value ? 'text' : 'password'}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Icon icon="fa6-solid:key" color="#637381" width="18" height="18" />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={oldShow.onToggle}>
                                            <Icon
                                                icon={oldShow.value ? 'mdi:eye-off' : 'mdi:eye'}
                                                color="#637381"
                                                width="25"
                                                height="25"
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <RHFTextField
                            name="newpassword"
                            label="Password"
                            type={passwordShow.value ? 'text' : 'password'}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Icon icon="fa6-solid:key" color="#637381" width="18" height="18" />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={passwordShow.onToggle}>
                                            <Icon
                                                icon={passwordShow.value ? 'mdi:eye-off' : 'mdi:eye'}
                                                color="#637381"
                                                width="25"
                                                height="25"
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />

                        <RHFTextField
                            name="confirmpassword"
                            label="Confirm Password"
                            type={confirmShow.value ? 'text' : 'password'}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Icon icon="fa6-solid:key" color="#637381" width="18" height="18" />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={confirmShow.onToggle}>
                                            <Icon
                                                icon={confirmShow.value ? 'mdi:eye-off' : 'mdi:eye'}
                                                color="#637381"
                                                width="25"
                                                height="25"
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />

                        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
                        <Stack width="100%" spacing={2}>
                            <LoadingButton
                                loading={isSubmitting}
                                size="large"
                                variant="contained"
                                type="submit"
                                fullWidth
                            >
                                Update
                            </LoadingButton>
                        </Stack>
                    </Stack>
                </DialogContent>
            </FormProvider>
        </Dialog>
    );
};
export default ChangePassword;
