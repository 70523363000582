import { useCallback, useEffect } from 'react';
import { Icon } from '@iconify/react';
import * as Yup from 'yup';
import { Link as ReactLink } from 'react-router-dom';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { LoadingButton } from '@mui/lab';

import logoIcon from 'assets/img/logo/logo.png';
// hook
import useAuth from 'hooks/useAuth';
import useSettings from 'hooks/useSettings';
// utils
import { ASSETS } from 'utils/axios';
import { useRouter } from 'hooks/use-router';
import { useBoolean } from 'hooks/use-boolean';

type FormValuesProps = {
    username: string;
    password: string;
    afterSubmit?: string;
};

const LoginModal = () => {
    const { loginModal, onChangeLoginModal, config } = useSettings();
    const { login } = useAuth();
    const router = useRouter();

    const remeberMe = useBoolean();
    const showPassword = useBoolean();

    const LoginSchema = Yup.object().shape({
        username: Yup.string().required('Username is required'),
        password: Yup.string().required('Password is required')
    });

    const defaultValues = {
        username: '',
        password: ''
    };

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(LoginSchema),
        defaultValues
    });

    const {
        control,
        setValue,
        setError,
        handleSubmit,
        formState: { errors, isSubmitting }
    } = methods;

    const onSubmit = async (data: any) => {
        try {
            await login(data.username, data.password, remeberMe.value);
            router.push('/sports');
        } catch (error: any) {
            setError('afterSubmit', {
                ...error,
                message: error.message || error
            });
        }
    };

    const closeModal = useCallback(() => {
        if (isSubmitting) return;
        onChangeLoginModal(false);
    }, [isSubmitting]);

    useEffect(() => {
        const rememberString = localStorage.getItem('hattrick247');
        if (rememberString) {
            const rememberData = JSON.parse(rememberString);
            if (rememberData.username) {
                setValue('username', rememberData.username);
            }
            if (rememberData.password) {
                setValue('password', rememberData.password);
            }
            remeberMe.onTrue();
        }
    }, [setValue]);

    return (
        <Dialog
            open={loginModal}
            onClose={closeModal}
            sx={{
                '& .MuiDialog-paper': {
                    border: '1px dashed #919eab',
                    borderColor: 'info.dark',
                    bgcolor: '#284864'
                }
            }}
        >
            <DialogContent sx={{ pb: 3 }}>
                <Box sx={{ py: 4, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Box
                        component="img"
                        src={ASSETS(config?.domain.logo || logoIcon)}
                        alt="logo"
                        sx={{ maxWidth: 200 }}
                    />
                </Box>
                <Stack spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                        name="username"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                fullWidth
                                error={!!error}
                                helperText={error ? error?.message : ''}
                                variant="filled"
                                label="Username"
                                sx={{ minWidth: { sm: 400, xs: '100%' } }}
                                inputProps={{
                                    autoComplete: 'off'
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon icon="fa6-solid:user" color="#637381" width="18" height="18" />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        )}
                    />
                    <Controller
                        name="password"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                fullWidth
                                error={!!error}
                                helperText={error ? error?.message : ''}
                                variant="filled"
                                label="Password"
                                type={showPassword.value ? 'text' : 'password'}
                                sx={{ minWidth: { sm: 400, xs: '100%' } }}
                                inputProps={{
                                    autoComplete: 'off'
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon icon="fa6-solid:key" color="#637381" width="18" height="18" />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={showPassword.onToggle}>
                                                <Icon
                                                    icon={showPassword.value ? 'mdi:eye-off' : 'mdi:eye'}
                                                    color="#637381"
                                                    width="25"
                                                    height="25"
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        )}
                    />
                    {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Checkbox checked={remeberMe.value} onChange={remeberMe.onToggle} />
                        <Typography sx={{}}>Remeber me</Typography>
                    </Stack>
                    <Stack width="100%" spacing={2}>
                        <LoadingButton loading={isSubmitting} size="large" variant="contained" type="submit" fullWidth>
                            Login
                        </LoadingButton>
                        <Typography
                            component={ReactLink}
                            to="/auth/forgot-password"
                            sx={{
                                textAlign: 'center',
                                cursor: 'pointer',
                                '&:hover': { color: 'text.primary' }
                            }}
                        >
                            Forgot Password?
                        </Typography>

                        {config?.IsClientsSideRegistration && (
                            <div style={{ textAlign: 'center' }}>
                                <Typography component="span" variant="body2" sx={{ opacity: 0.8, fontSize: 14 }}>
                                    Don't have an account?
                                </Typography>{' '}
                                &nbsp;
                                <Typography
                                    component={ReactLink}
                                    to="/auth/register"
                                    noWrap
                                    sx={{ fontWeight: 'bold', fontSize: 14 }}
                                >
                                    Create new account
                                </Typography>
                            </div>
                        )}
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};
export default LoginModal;
