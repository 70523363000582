// @mui
import { styled, alpha } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';

// ----------------------------------------------------------------------
export function bgBlur(props) {
    const color = props?.color || '#000000';
    const blur = props?.blur || 6;
    const opacity = props?.opacity || 0.8;
    const imgUrl = props?.imgUrl;

    if (imgUrl) {
        return {
            position: 'relative',
            backgroundImage: `url(${imgUrl})`,
            '&:before': {
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 9,
                content: '""',
                width: '100%',
                height: '100%',
                backdropFilter: `blur(${blur}px)`,
                WebkitBackdropFilter: `blur(${blur}px)`,
                backgroundColor: alpha(color, opacity)
            }
        };
    }

    return {
        backdropFilter: `blur(${blur}px)`,
        WebkitBackdropFilter: `blur(${blur}px)`,
        backgroundColor: alpha(color, opacity)
    };
}

// ----------------------------------------------------------------------

export const StyledRootScrollbar = styled('div')(() => ({
    flexGrow: 1,
    height: '100%',
    overflow: 'hidden'
}));

export const BackButton = styled(Button)(({ theme }) => ({
    px: 0,
    py: 0.8,
    width: 50,
    height: 30,
    minWidth: 50,
    borderRadius: 2,
    background: 'linear-gradient(90deg,#457fca -66.05%,#84c2f1 93%)'
}));

export const BackOutButton = styled(Button)(({ theme }) => ({
    px: 0,
    py: 0.8,
    width: 50,
    height: 30,
    minWidth: 50,
    borderRadius: 2
}));

export const LayButton = styled(Button)(({ theme }) => ({
    px: 0,
    py: 0.8,
    width: 50,
    height: 30,
    minWidth: 50,
    borderRadius: 2,
    background: 'linear-gradient(90deg,#f4c4f3 -66.05%,#f59dc2 93%)'
}));

export const LayOutButton = styled(Button)(({ theme }) => ({
    px: 0,
    py: 0.8,
    width: 50,
    height: 30,
    minWidth: 50,
    borderRadius: 2
}));

export const StyledMenu = styled(Paper)(({ theme }) => ({
    ...bgBlur({
        opacity: 0.94,
        color: theme.palette.background.default
    }),
    top: 72,
    left: 0,
    right: 0,
    margin: 'auto',
    display: 'grid',
    position: 'absolute',
    alignItems: 'flex-start',
    zIndex: theme.zIndex.modal,
    padding: theme.spacing(5, 1, 1, 3),
    boxShadow: theme.customShadows.dialog,
    maxWidth: theme.breakpoints.values.lg,
    gridTemplateColumns: 'repeat(12, 1fr)',
    borderRadius: Number(theme.shape.borderRadius) * 2,
    border: `solid 1px ${alpha(theme.palette.grey[500], 0.16)}`
}));

export const StyledPopover = styled(Popover)(({ theme }) => ({
    // pointerEvents: 'none',
    '& .MuiPopover-paper': {
        minWidth: 160,
        pointerEvents: 'auto',
        padding: theme.spacing(1),
        marginTop: theme.spacing(0.5),
        boxShadow: theme.customShadows.dropdown,
        borderRadius: Number(theme.shape.borderRadius) * 1.5,
        ...bgBlur({ color: theme.palette.success.darker })
    }
}));

export const StyledDepositBtn = styled(Button)(({ theme }) => ({
    borderRadius: 50,
    backgroundImage: 'linear-gradient(to right, #0f9, #0a0)',
    outline: 0,
    '&::after': {
        position: 'absolute',
        content: `''`,
        bottom: -4,
        top: -4,
        left: -4,
        right: -4,
        backgroundColor: 'transparent',
        borderRadius: 50,
        border: '2px solid #b1b2b5',
        margin: 0,
        opacity: 1,
        transformOrigin: 'center',
        animation: 'anim 800ms linear infinite'
    },
    '&::before': {
        position: 'absolute',
        content: `''`,
        bottom: -4,
        top: -4,
        left: -4,
        right: -4,
        backgroundColor: 'transparent',
        borderRadius: 50,
        border: '2px solid #b1b2b5',
        margin: 0,
        opacity: 0.8,
        transformOrigin: 'center',
        animation: 'anim 800ms linear 400ms infinite'
    }
}));
