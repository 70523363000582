import Header from 'components/Header/CasinoHeader';
import Footer from 'components/Footer';

import useSetting from 'hooks/useSettings';
import useResponsive from 'hooks/useResponsive';

import UserWrapper from './UserWrapper';
import Navbar from 'components/Navbar';
import Marquee from 'components/marquee';
import MobileMenu from 'components/MobileMenu';
import MobileHeader from 'components/Header/MobileHeader';

const UserLayout = () => {
    const isDesktop = useResponsive('up', 'sm');
    const { navbar, onChangeNav } = useSetting();

    return (
        <>
            {navbar === 'open' && !isDesktop && <Navbar openNav={navbar === 'open'} onCloseNav={onChangeNav} />}
            {isDesktop ? <Header /> : <MobileHeader />}
            <Marquee />
            <UserWrapper />
            <Footer />
            <MobileMenu />
        </>
    );
};

export default UserLayout;
