import { Link as TagLink } from 'react-router-dom';
// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// static
import { footerList, socialIcon } from 'config/constant';
// payment image
import {
    GooglePay,
    BitCoin,
    Ether,
    Tether,
    Paytm,
    Phonepe
    // Visa,
    // Master,
    // ApplePay,
    // Qiwi,
    // Skrill,
    // Payneer,
    // Cos,
    // FK,
    // WebMoney,
    // MuchBetter,
    // JCB,
    // Discover,
    // Interace,
    // AstroPay
} from 'assets/img/icon/svgIcon';

import logoIcon from 'assets/img/logo/logo.png';
import verifiedIcon from 'assets/img/icon/verified.png';
import useResponsive from 'hooks/useResponsive';
// hook
import useSettings from 'hooks/useSettings';
// utils
import { ASSETS } from 'utils/axios';

const Footer = () => {
    const { config } = useSettings();
    const isDesktop = useResponsive('up', 'md');
    return (
        <Stack component="footer" sx={{ bgcolor: 'background.paper', pt: 4, pb: { xs: '61px', sm: 0 } }}>
            <Container maxWidth="xl">
                <Stack direction={{ md: 'row', sm: 'column' }} alignItems="flex-end" justifyContent="space-between">
                    <Stack sx={{ width: 1, alignItems: { md: 'flex-start', xs: 'center' } }}>
                        <Box>
                            <Box
                                component="img"
                                src={ASSETS(config?.domain.logo || logoIcon)}
                                alt="logo"
                                sx={{ maxWidth: 170, mb: 2, maxHeight: 40 }}
                            />
                        </Box>
                        <Typography sx={{ fontSize: 12, opacity: 0.8, textAlign: { sm: 'left', xs: 'center' } }}>
                            © Established in 2024, Hattrick is the most trusted betting exchange and leading online
                            casino.
                        </Typography>
                    </Stack>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent={{ md: 'flex-end', xs: 'center' }}
                        sx={{ flexWrap: 'wrap', pt: 6, gap: 2, width: 1 }}
                    >
                        {config?.socials
                            .filter((social) => social.link)
                            .map(({ name, link }: any, i: number) => (
                                <IconButton sx={{ p: 0 }} key={i} component={TagLink} target="_blank" to={link}>
                                    <Box component="img" src={socialIcon[name]} alt="s" width={36} height={36} />
                                </IconButton>
                            ))}
                    </Stack>
                </Stack>
                <Grid container spacing={isDesktop ? 0 : 3} sx={{ display: 'none' }}>
                    <Grid item md={3} xs={12}>
                        <Stack sx={{ pr: { sm: 6, xs: 0 }, alignItems: { sm: 'flex-start', xs: 'center' } }}>
                            <Box
                                component="img"
                                src={ASSETS(config?.domain.logo || logoIcon)}
                                alt="logo"
                                sx={{ maxWidth: 170, mb: 2, maxHeight: 40 }}
                            />
                            <Typography sx={{ fontSize: 12, opacity: 0.8, textAlign: { sm: 'left', xs: 'center' } }}>
                                © Established in 2024, Hattrick is the most trusted betting exchange and leading online
                                casino.
                            </Typography>
                        </Stack>
                    </Grid>
                    {footerList.map((data: any, i: number) => (
                        <Grid item md={footerList.length - 1 === i ? 1 : 2} xs={6} key={i}>
                            <Typography
                                variant="h5"
                                textTransform="uppercase"
                                sx={{ height: { sm: 30, xs: 20 }, mb: { sm: 2, xs: 1 } }}
                            >
                                {data.title}
                            </Typography>
                            <Stack>
                                {data.list.map((item: any, j: number) => (
                                    <Box key={j}>
                                        <Link
                                            color="inherit"
                                            component={TagLink}
                                            to={item.link}
                                            sx={{ fontSize: 14, mb: 1, opacity: 0.7 }}
                                        >
                                            {item.title}
                                        </Link>
                                    </Box>
                                ))}
                            </Stack>
                        </Grid>
                    ))}
                    <Grid item md={6} xs={12} />
                    <Grid item md={3} xs={12}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            sx={{ flexWrap: 'wrap', pt: 6, gap: 2 }}
                        >
                            {config?.socials
                                .filter((social) => social.link)
                                .map(({ name, link }: any, i: number) => (
                                    <IconButton sx={{ p: 0 }} key={i} component={TagLink} target="_blank" to={link}>
                                        <Box component="img" src={socialIcon[name]} alt="s" width={36} height={36} />
                                    </IconButton>
                                ))}
                        </Stack>
                    </Grid>
                </Grid>
                <Divider sx={{ mt: 3 }} />
                <Box
                    sx={{
                        my: 3,
                        gap: 2,
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'row',
                        '& svg': {
                            cursor: 'pointer',
                            fontSize: 24,
                            width: 'auto',
                            '& [class$=color]': {
                                opacity: 0,
                                transition: 'opacity 0.2s ease'
                            },
                            '& [class$=mono]': {
                                opacity: 1,
                                transition: 'opacity 0.2s ease'
                            },
                            '&:hover': {
                                '& [class$=color]': {
                                    opacity: 1
                                },
                                '& [class$=mono]': {
                                    opacity: 0
                                }
                            }
                        }
                    }}
                >
                    {/* <Visa /> */}
                    {/* <Master /> */}
                    <GooglePay />
                    {/* <ApplePay /> */}
                    <BitCoin />
                    {/* <Qiwi /> */}
                    <Ether />
                    <Tether />
                    {/* <Skrill /> */}
                    <Paytm />
                    <Phonepe />
                    {/* <Payneer /> */}
                    {/* <Cos /> */}
                    {/* <FK /> */}
                    {/* <WebMoney /> */}
                    {/* <MuchBetter /> */}
                    {/* <JCB /> */}
                    {/* <Discover /> */}
                    {/* <Interace /> */}
                    {/* <AstroPay /> */}
                </Box>
                <Divider />
                <Stack direction="row" alignItems="center" padding={2}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ textDecoration: 'none', color: '#34405e', fontSize: 16, fontWeight: 800, mr: 2 }}
                    >
                        <Link>
                            <Box component="img" src={verifiedIcon} sx={{ width: 50 }} />
                        </Link>
                        18+
                    </Stack>
                    <Box>
                        <Typography sx={{ opacity: 0.7, fontSize: 14 }}>Disclaimer :</Typography>
                        <Typography sx={{ opacity: 0.7, fontSize: 12 }}>
                            Please note that Gaming involves a financial risk and could be addictive over time if not
                            practised within limits. Only 18+ people should use the services and should use it
                            responsibly. Players should be aware of any financial risk and govern themselves
                            accordingly.
                        </Typography>
                    </Box>
                </Stack>
                <Divider />
                <Typography sx={{ fontSize: 12, textAlign: 'center', my: 2, opacity: 0.7 }}>
                    © 2024 Hattrick. All Rights Reserved.
                </Typography>
            </Container>
        </Stack>
    );
};

export default Footer;
