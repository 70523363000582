// Mui
import Box from '@mui/material/Box';

// components
import GameList from './GameList';

const Spribe = () => {
    return (
        <Box sx={{ pb: 4 }}>
            <GameList />
        </Box>
    );
};

export default Spribe;
