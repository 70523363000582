import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
//
import { headerList } from 'config/constant';
//
import logoIcon from 'assets/img/logo/logo.png';
import useAuth from 'hooks/useAuth';
import useSettings from 'hooks/useSettings';
import { HeaderItemProps } from 'types/sports';
import { ASSETS } from 'utils/axios';
//
import UserPart from './UserPart';

const CasinoHeader = () => {
    const { user, isLogined } = useAuth();

    const { config } = useSettings();

    const allowedList = useMemo(() => {
        if (isLogined && user && user.permissions) {
            return headerList.filter((e: HeaderItemProps) => !(user.permissions[e.title] === false));
        }
        return headerList;
    }, [isLogined, user, headerList]);

    return (
        <Box
            component="header"
            sx={{
                top: 0,
                zIndex: 12,
                width: '100%',
                position: 'sticky',
                bgcolor: (theme) => theme.palette.background.default
            }}
        >
            <Box sx={{ bgcolor: '#284864' }}>
                <Container maxWidth="xl">
                    <Stack
                        direction="row"
                        alignItems="center"
                        spacing={0.5}
                        sx={{
                            py: 0.3,
                            px: 0.5,
                            width: '100%'
                        }}
                    >
                        <RouterLink to="/home">
                            <Box
                                component="img"
                                src={config && config.domain.logo ? ASSETS(config?.domain.logo) : logoIcon}
                                alt="logo"
                                sx={{ maxWidth: 170, maxHeight: 35 }}
                            />
                        </RouterLink>
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={0.3}
                            sx={{ overflow: 'auto', width: '100%', py: 0.3 }}
                        >
                            {allowedList.map((item: { title: string; icon: string; link: string }, i: number) => (
                                <div key={i} style={{ flexGrow: 1, textAlign: 'center' }}>
                                    <ButtonBase
                                        color="inherit"
                                        component={RouterLink}
                                        to={item.link}
                                        sx={{
                                            p: 1,
                                            minWidth: 60,
                                            borderRadius: 1,
                                            color: 'text.primary',
                                            background: 'unset',
                                            '&:hover': { bgcolor: 'background.paper' }
                                        }}
                                    >
                                        <Stack alignItems="center">
                                            <Box
                                                component="img"
                                                src={item.icon}
                                                alt="item"
                                                sx={{ width: 25, height: 25 }}
                                            />
                                            <Typography
                                                sx={{
                                                    fontSize: 14,
                                                    fontWeight: 600,
                                                    whiteSpace: 'nowrap'
                                                }}
                                            >
                                                {item.title}
                                            </Typography>
                                        </Stack>
                                    </ButtonBase>
                                </div>
                            ))}
                        </Stack>
                        <UserPart />
                    </Stack>
                </Container>
            </Box>
        </Box>
    );
};

export default CasinoHeader;
