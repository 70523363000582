// Header item icons
import exchangeIcon from 'assets/img/header/Exchange.svg';
import inPlayIcon from 'assets/img/header/InPlay.svg';
import evolutionIcon from 'assets/img/header/evolution.png';
import ezugiIcon from 'assets/img/header/ezugi.png';
import casinoIcon from 'assets/img/header/Casino.svg';
import promotionIcon from 'assets/img/header/Promotion.svg';
import cricketIcon from 'assets/img/header/Cricket.svg';
import soccerIcon from 'assets/img/header/Soccer.svg';
import tennisIcon from 'assets/img/header/Tennis.svg';
import pokerIcon from 'assets/img/header/Poker.png';
import aviatorIcon from 'assets/img/header/Aviator.svg';
import horseRacingIcon from 'assets/img/header/HorseRacing.webp';

// sports icons
import greyhound from 'assets/img/sports/Greyhound.svg';
import mCricket from 'assets/img/sports/mobile/Cricket.webp';
import mFootball from 'assets/img/sports/mobile/Football.webp';
import mTennis from 'assets/img/sports/mobile/Tennis.webp';
import mGreyhound from 'assets/img/sports/mobile/Greyhound.webp';
import mHorseRacing from 'assets/img/sports/mobile/HorseRacing.webp';

// sports banner
import bCricket from 'assets/img/bg-sports/4.webp';
import bSoccer from 'assets/img/bg-sports/1.webp';
import bTennis from 'assets/img/bg-sports/2.webp';
import bGrey from 'assets/img/bg-sports/7.png';
import bHorse from 'assets/img/bg-sports/4339.png';
// import mSeeall from 'assets/img/sports/mobile/SeeAll.webp';

// crahs game icons
import aviatorGameIcon from 'assets/img/crash/aviator.png';
import diceIcon from 'assets/img/crash/dice.png';
import goalIcon from 'assets/img/crash/goal.png';
import plinkoIcon from 'assets/img/crash/plinko.png';
import minesIcon from 'assets/img/crash/mines.png';
import hiloIcon from 'assets/img/crash/hilo.png';
import kenoIcon from 'assets/img/crash/keno.png';
import miniRouletteIcon from 'assets/img/crash/mini-roulette.png';
import hotlineIcon from 'assets/img/crash/hotline.png';
// import pokerGameIcon from 'assets/img/crash/poker.png';
// import keno80Icon from 'assets/img/crash/keno80.png';

// social icons
import facebookIcon from 'assets/img/socials/facebook.svg';
import xIcon from 'assets/img/socials/X.svg';
import discordIcon from 'assets/img/socials/Discord.svg';
import whatsappIcon from 'assets/img/socials/Whatsapp.svg';
import telegramIcon from 'assets/img/socials/Telegram.svg';
import instagramIcon from 'assets/img/socials/instagram.svg';
import youtubeIcon from 'assets/img/socials/Youtube.svg';
import linkedInIcon from 'assets/img/socials/LinkedIn.svg';
import { HeaderItemProps, SportDataProps } from 'types/sports';

export const headerList: HeaderItemProps[] = [
    {
        title: 'Sports',
        icon: exchangeIcon,
        link: '/sports'
    },
    {
        title: 'In Play',
        icon: inPlayIcon,
        link: '/sports/inplay'
    },
    {
        title: 'Cricket',
        icon: cricketIcon,
        link: '/sports/4'
    },
    {
        title: 'Aviator',
        icon: aviatorIcon,
        link: '/spribe'
    },
    {
        title: 'Soccer',
        icon: soccerIcon,
        link: '/sports/1'
    },
    {
        title: 'Tennis',
        icon: tennisIcon,
        link: '/sports/2'
    },
    {
        title: 'Horse Racing',
        icon: horseRacingIcon,
        link: '/sports/7'
    },
    {
        title: 'Greyhound Racing',
        icon: greyhound,
        link: '/sports/4339'
    },
    {
        title: 'Ezugi',
        icon: ezugiIcon,
        link: '/casino/ezugi'
    },
    {
        title: 'Evolution',
        icon: evolutionIcon,
        link: '/casino/evolution'
    },
    {
        title: 'All Casino',
        icon: casinoIcon,
        link: '/casino'
    },
    {
        title: 'Poker',
        icon: pokerIcon,
        link: '/poker'
    },
    {
        title: 'Promotions',
        icon: promotionIcon,
        link: '/promotion'
    }
];

export const SPORTS_NAME_LIST = ['Cricket', 'Tennis', 'Soccer', 'Horse Racing', 'Greyhound Racing'];

export const sportsList = [
    {
        title: 'Cricket',
        icon: cricketIcon,
        mobile: mCricket,
        id: '4'
    },
    {
        title: 'Soccer',
        icon: soccerIcon,
        mobile: mFootball,
        id: '1'
    },
    {
        title: 'Tennis',
        icon: tennisIcon,
        mobile: mTennis,
        id: '2'
    },
    {
        title: 'Horse Racing',
        icon: horseRacingIcon,
        mobile: mHorseRacing,
        id: '7'
    },
    {
        title: 'Greyhound Racing',
        icon: greyhound,
        mobile: mGreyhound,
        id: '4339'
    }
];

export const sportIcons = {
    Cricket: cricketIcon,
    Tennis: tennisIcon,
    Soccer: soccerIcon,
    'Horse Racing': horseRacingIcon,
    'Greyhound Racing': greyhound
};

export const sportDataById: Record<string, SportDataProps> = {
    '1': { icon: soccerIcon, bImg: bSoccer, name: 'Soccer' },
    '2': { icon: tennisIcon, bImg: bTennis, name: 'Tennis' },
    '4': { icon: cricketIcon, bImg: bCricket, name: 'Cricket' },
    '7': { icon: horseRacingIcon, bImg: bGrey, name: 'Horse Racing' },
    '4339': { icon: greyhound, bImg: bHorse, name: 'Greyhound Racing' }
};

export const spribeGameList = [
    {
        title: 'Aviator',
        game: 'aviator',
        icon: aviatorGameIcon
    },
    {
        title: 'Dice',
        game: 'dice',
        icon: diceIcon
    },
    {
        title: 'Goal',
        game: 'goal',
        icon: goalIcon
    },
    {
        title: 'Plinko',
        game: 'plinko',
        icon: plinkoIcon
    },
    {
        title: 'Mines',
        game: 'mines',
        icon: minesIcon
    },
    {
        title: 'Hi Lo',
        game: 'hi-lo',
        icon: hiloIcon
    },
    {
        title: 'Keno',
        game: 'keno',
        icon: kenoIcon
    },
    {
        title: 'Mini Roulette',
        game: 'mini-roulette',
        icon: miniRouletteIcon
    },
    {
        title: 'Hotline',
        game: 'hotline',
        icon: hotlineIcon
    }
    // {
    //     title: 'Poker',
    //     game: 'poker',
    //     icon: pokerIcon
    // },
    // {
    //     title: 'Keno 80',
    //     game: 'multikeno',
    //     icon: keno80Icon
    // }
];

export const footerList = [];
export const footerListOld = [
    {
        title: 'Game',
        list: [
            { title: 'Online Sports Betting', link: '/' },
            { title: 'Card Game', link: '/' },
            { title: 'Cricket Betting', link: '/' },
            { title: 'IPL Betting', link: '/' },
            { title: 'Football Betting', link: '/' },
            { title: 'Tennis Betting', link: '/' },
            { title: 'Live Casino Betting', link: '/' },
            { title: 'Hattrick247 Online Betting App', link: '/' }
        ]
    },
    {
        title: 'Company',
        list: [
            { title: 'About Us', link: '/' },
            { title: 'Rules & Regulation', link: '/' },
            { title: 'Privacy Policy', link: '/' },
            { title: 'Sitemap', link: '/' },
            { title: 'Responsible Gambling', link: '/' },
            { title: 'Terms And Conditions', link: '/' }
        ]
    },
    {
        title: 'Resources',
        list: [
            { title: 'Blogs', link: '/' },
            { title: 'Fairplay News', link: '/' },
            { title: 'Premium Sportsbook Betting', link: '/' },
            { title: 'Affiliate', link: '/' }
        ]
    },
    {
        title: '',
        list: [
            { title: 'FAQ', link: '/' },
            { title: 'Tutorials', link: '/' },
            { title: 'Market', link: '/' },
            { title: 'Loyalty', link: '/' },
            { title: 'Contact Us', link: '/' }
        ]
    }
];

export const socialIcon = {
    facebook: facebookIcon,
    x: xIcon,
    telegram: telegramIcon,
    instagram: instagramIcon,
    youtube: youtubeIcon,
    linkedin: linkedInIcon,
    discord: discordIcon,
    whatsapp: whatsappIcon
};

export const HEADER = {
    H_DASHBOARD_DESKTOP: 130
};

export const NAV = {
    W_DASHBOARD_MINI: 0,
    W_DASHBOARD: 320
};

export const idBySport = {
    Soccer: 1,
    Tennis: 2,
    Cricket: 4,
    'Horse Racing': 7,
    'Greyhound Racing': 4339
};

export const PageNationPerPage = [10, 25, 50, 100];

export const auraKey = 9573;

// export const spribeLaunch = `https://dev-test.spribe.io/games/launch/%7Bgame%7D`;
// export const spribeOperatorKey = 'oddfancycom';
export const spribeLaunch = `https://cdnet-launch.apac.spribegaming.com`;
export const spribeLaunch_v2 = `https://api.spbav.com/api/user/auth`;

export const spribeOperatorKey = 'oddsfancy';

export const st8Domain = `https://sae001.o.st8api.com`;
export const st8Domain_test = `https://sae001.o.p8d.xyz`;
export const st8LaunchDomain = `https://luckplay.link`;
export const st8LaunchDomain_test = `https://stage1.luckplay.link`;
export const st8OperatorCode = 'sae001';

export const LevelChartSeries = [20, 22, 24, 26, 28];

export const LevelColors = ['#f6922b', '#c2cad0', '#ffb70a', '#a9b1c7', '#5f9cb9'];

// const list: listItemProps[] = [
// {
//     title: 'profile',
//     order: 0,
//     link: '/profile',
//     icon: <Icon icon="mingcute:user-4-fill" width="24" height="24" />
// },
// {
//     title: 'deposit',
//     link: '/payment/deposit',
//     icon: <Icon icon="mingcute:bank-fill" width="24" height="24" />
// },
// {
//     title: 'withdraw',
//     link: '/payment/withdraw',
//     icon: <Icon icon="maki:bank" width="24" height="24" />
// },
// {
//     order: 3,
//     title: 'account statement',
//     link: '/reports/account-statement',
//     icon: <Icon icon="game-icons:receive-money" width="24" height="24" />
// },
// {
//     order: 4,
//     title: 'game report',
//     link: '/reports/profit-loss',
//     icon: <Icon icon="octicon:log-16" width="24" height="24" />
// },
// {
//     title: 'Bonus',
//     link: '/reports/bonus',
//     icon: <Icon icon="fa6-solid:gift" width="24" height="24" />
// },
// {
//     order: 6,
//     title: 'my bets',
//     link: '/reports/mybets',
//     icon: <Icon icon="mdi:book-alphabet" width="24" height="24" />
// },
// {
//     order: 7,
//     title: 'Chip Settings',
//     link: '/setting/stake',
//     icon: <Icon icon="mdi:poker-chip" width="24" height="24" />
// }
// {
//     title: 'change passowrd',
//     link: 'change-passowrd',
//     icon: <Icon icon="mdi:pencil-lock" width="24" height="24" />
// },
// {
//     title: 'activity log',
//     link: 'activity-log',
//     icon: <Icon icon="uim:history-alt" width="24" height="24" />
// }
// ];

export const gamesList = [
    {
        _id: '65dc5cae4b08ad949fc7bc7c',
        code: 'spn_dark_wolf',
        __v: 0,
        bonus_types: ['free_bets'],
        category: 'Video Slots',
        createdAt: '2024-02-26T09:41:02.923Z',
        deprecation_date: null,
        developer: 'Spinomenal',
        enabled: true,
        features: ['Bonus Buy', 'Feature Choice'],
        fun_mode: true,
        hit_ratio: '26.0',
        name: 'Dark Wolf',
        release_date: '2022-06-30',
        rtp: '92.16',
        themes: [],
        updatedAt: '2024-02-27T11:09:21.608Z',
        volatility: 3
    },
    {
        _id: '65dc5c204b08ad949fc68199',
        code: 'crc_captain_of_pirates',
        __v: 0,
        bonus_types: [],
        category: 'Video Slots',
        createdAt: '2024-02-26T09:38:40.608Z',
        deprecation_date: null,
        developer: 'Charismatic',
        enabled: true,
        features: [],
        fun_mode: true,
        hit_ratio: null,
        name: 'Captain Of Pirates',
        release_date: null,
        rtp: '97',
        themes: [],
        updatedAt: '2024-02-27T11:06:58.858Z',
        volatility: null
    },
    {
        _id: '65dc5c014b08ad949fc63e69',
        code: 'ezg_blackjack_gold_5',
        __v: 0,
        bonus_types: [],
        category: 'Live Blackjack',
        createdAt: '2024-02-26T09:38:09.811Z',
        deprecation_date: null,
        developer: 'Ezugi',
        enabled: true,
        features: [''],
        fun_mode: false,
        hit_ratio: null,
        name: 'Blackjack Gold 5',
        release_date: null,
        rtp: null,
        themes: [''],
        updatedAt: '2024-02-27T11:15:24.409Z',
        volatility: null
    },
    {
        _id: '65dc5cdd4b08ad949fc82319',
        code: 'wzd_9_burning_dragons',
        __v: 0,
        bonus_types: ['free_bets'],
        category: 'Video Slots',
        createdAt: '2024-02-26T09:41:49.750Z',
        deprecation_date: null,
        developer: 'Wazdan',
        enabled: true,
        features: ['Gamble', 'Respin', 'Bonus Buy', 'In-game Freespins'],
        fun_mode: true,
        hit_ratio: '21.5',
        name: '9 Burning Dragons',
        release_date: '2022-04-20',
        rtp: '94.23',
        themes: [],
        updatedAt: '2024-02-27T11:10:08.252Z',
        volatility: 5
    },
    {
        _id: '65dc5c3c4b08ad949fc6bdcd',
        code: 'hbn_lucky_durian',
        __v: 0,
        bonus_types: [],
        category: 'Video Slots',
        createdAt: '2024-02-26T09:39:08.104Z',
        deprecation_date: null,
        developer: 'Habanero',
        enabled: true,
        features: ['Progressive Jackpot'],
        fun_mode: true,
        hit_ratio: null,
        name: 'Lucky Durian',
        release_date: '2022-01-24',
        rtp: '94.04',
        themes: [],
        updatedAt: '2024-02-27T11:07:26.670Z',
        volatility: 5
    },
    {
        _id: '65dc5c094b08ad949fc64e20',
        code: 'png_15_crystal_roses_a_tale_of_love',
        __v: 0,
        bonus_types: [],
        category: 'Video Slots',
        createdAt: '2024-02-26T09:38:17.270Z',
        deprecation_date: null,
        developer: "Play'n Go",
        enabled: true,
        features: ['In-game Progress', 'In-game Freespins'],
        fun_mode: true,
        hit_ratio: null,
        name: '15 Crystal Roses: A Tale of Love',
        release_date: '2022-01-13',
        rtp: '94.20',
        themes: [],
        updatedAt: '2024-02-27T11:15:32.324Z',
        volatility: 4
    },
    {
        _id: '65dc5c6f4b08ad949fc72d9d',
        code: 'pltl_x1000_quantum_roulette',
        __v: 0,
        bonus_types: [],
        category: 'Live Roulette',
        createdAt: '2024-02-26T09:39:59.834Z',
        deprecation_date: null,
        developer: 'Playtech Live',
        enabled: true,
        features: [],
        fun_mode: false,
        hit_ratio: null,
        name: 'x1000 Quantum Roulette',
        release_date: null,
        rtp: '97.29',
        themes: [],
        updatedAt: '2024-02-27T11:08:18.646Z',
        volatility: null
    },
    {
        _id: '65dc5c504b08ad949fc6ea26',
        code: 'onegame_big_rich_fortune',
        __v: 0,
        bonus_types: [],
        category: 'Video Slots',
        createdAt: '2024-02-26T09:39:28.877Z',
        deprecation_date: null,
        developer: 'OneGame',
        enabled: true,
        features: ['In-game Freespins'],
        fun_mode: true,
        hit_ratio: null,
        name: 'Big Rich Fortune',
        release_date: null,
        rtp: '95.9',
        themes: [],
        updatedAt: '2024-02-27T11:07:47.736Z',
        volatility: null
    },
    {
        _id: '65dc5ccf4b08ad949fc803fe',
        code: 'tvb_wheelbet',
        __v: 0,
        bonus_types: [],
        category: 'Game Show',
        createdAt: '2024-02-26T09:41:35.952Z',
        deprecation_date: null,
        developer: 'TVBet',
        enabled: true,
        features: [],
        fun_mode: false,
        hit_ratio: null,
        name: 'Wheelbet',
        release_date: null,
        rtp: null,
        themes: [],
        updatedAt: '2024-02-27T11:09:54.480Z',
        volatility: null
    },
    {
        _id: '65dc5c7e4b08ad949fc74e73',
        code: 'reg_attila_the_hun',
        __v: 0,
        bonus_types: ['free_bets'],
        category: 'Video Slots',
        createdAt: '2024-02-26T09:40:14.175Z',
        deprecation_date: null,
        developer: 'Relax Gaming',
        enabled: true,
        features: [],
        fun_mode: true,
        hit_ratio: null,
        name: 'Attila The Hun',
        release_date: '2020-02-17',
        rtp: '96.38',
        themes: [],
        updatedAt: '2024-02-27T11:08:32.537Z',
        volatility: null
    },
    {
        _id: '65dc5cf84b08ad949fc85cd1',
        code: 'aes_lobby',
        __v: 0,
        bonus_types: [],
        category: 'Live Lobby',
        createdAt: '2024-02-26T09:42:15.962Z',
        deprecation_date: null,
        developer: 'AE Sexy',
        enabled: false,
        features: [],
        fun_mode: false,
        hit_ratio: null,
        name: 'Lobby',
        release_date: null,
        rtp: null,
        themes: [],
        updatedAt: '2024-02-27T11:10:33.814Z',
        volatility: null
    },
    {
        _id: '65dc5cd64b08ad949fc81454',
        code: 'fnt_caravan_of_riches',
        __v: 0,
        bonus_types: [],
        category: 'Video Slots',
        createdAt: '2024-02-26T09:41:42.863Z',
        deprecation_date: null,
        developer: 'Fantasma Games',
        enabled: true,
        features: ['In-game Freespins', 'Bonus Game', 'Respin'],
        fun_mode: true,
        hit_ratio: '23.3',
        name: 'Caravan of Riches',
        release_date: null,
        rtp: '96.03',
        themes: [],
        updatedAt: '2024-02-27T11:10:01.060Z',
        volatility: 4
    },
    {
        _id: '65dc5ca34b08ad949fc7a2b0',
        code: 'oak_aztec_fire',
        __v: 0,
        bonus_types: [],
        category: 'Video Slots',
        createdAt: '2024-02-26T09:40:51.392Z',
        deprecation_date: null,
        developer: '3 Oaks Gaming',
        enabled: true,
        features: ['In-game Freespins', 'In-game Jackpot'],
        fun_mode: true,
        hit_ratio: '22.8',
        name: 'Aztec Fire',
        release_date: '2022-08-18',
        rtp: '95.5',
        themes: [],
        updatedAt: '2024-02-27T11:09:10.174Z',
        volatility: 4
    },
    {
        _id: '65dc5c4f4b08ad949fc6e7c0',
        code: 'ngg_god_of_fortune',
        __v: 0,
        bonus_types: [],
        category: 'Video Slots',
        createdAt: '2024-02-26T09:39:27.723Z',
        deprecation_date: null,
        developer: 'Naga Games',
        enabled: true,
        features: [],
        fun_mode: true,
        hit_ratio: null,
        name: 'God of Fortune',
        release_date: '2022-02-04',
        rtp: '96.43',
        themes: [],
        updatedAt: '2024-02-27T11:07:46.565Z',
        volatility: null
    },
    {
        _id: '65dc5c7b4b08ad949fc746c7',
        code: 'nlc_poison_eve',
        __v: 0,
        bonus_types: ['free_bets'],
        category: 'Video Slots',
        createdAt: '2024-02-26T09:40:11.245Z',
        deprecation_date: null,
        developer: 'Nolimit City',
        enabled: true,
        features: [],
        fun_mode: true,
        hit_ratio: null,
        name: 'Poison Eve',
        release_date: '2020-01-07',
        rtp: '94.35',
        themes: [],
        updatedAt: '2024-02-27T11:08:29.449Z',
        volatility: null
    },
    {
        _id: '65dc5c4b4b08ad949fc6deb5',
        code: 'gmz_really_hot',
        __v: 0,
        bonus_types: [],
        category: 'Video Slots',
        createdAt: '2024-02-26T09:39:23.410Z',
        deprecation_date: null,
        developer: 'Gamzix',
        enabled: true,
        features: ['Progressive Jackpot'],
        fun_mode: true,
        hit_ratio: null,
        name: 'Really Hot',
        release_date: '2020-06-12',
        rtp: '96.15',
        themes: [],
        updatedAt: '2024-02-27T11:07:42.147Z',
        volatility: null
    },
    {
        _id: '65dc5c974b08ad949fc787e2',
        code: 'jil_dragon_fortune',
        __v: 0,
        bonus_types: [],
        category: 'Fishing Games',
        createdAt: '2024-02-26T09:40:39.496Z',
        deprecation_date: null,
        developer: 'Jili Games',
        enabled: true,
        features: [],
        fun_mode: true,
        hit_ratio: null,
        name: 'Dragon Fortune',
        release_date: null,
        rtp: '97.0',
        themes: [],
        updatedAt: '2024-02-27T11:08:57.890Z',
        volatility: null
    },
    {
        _id: '65dc5ca94b08ad949fc7b02a',
        code: 'kng_sicbo',
        __v: 0,
        bonus_types: [],
        category: 'Live Sic Bo',
        createdAt: '2024-02-26T09:40:57.595Z',
        deprecation_date: null,
        developer: 'Kingmaker',
        enabled: true,
        features: [],
        fun_mode: false,
        hit_ratio: null,
        name: 'Sicbo',
        release_date: null,
        rtp: '97',
        themes: [],
        updatedAt: '2024-02-27T11:09:16.252Z',
        volatility: 4
    },
    {
        _id: '65dc5c374b08ad949fc6b4d5',
        code: 'ppl_roulette_2',
        __v: 0,
        bonus_types: [],
        category: 'Live Roulette',
        createdAt: '2024-02-26T09:39:03.916Z',
        deprecation_date: null,
        developer: 'Pragmatic Play Live',
        enabled: true,
        features: [],
        fun_mode: false,
        hit_ratio: null,
        name: 'Roulette 2',
        release_date: null,
        rtp: '97.30',
        themes: [],
        updatedAt: '2024-02-27T11:07:22.162Z',
        volatility: null
    },
    {
        _id: '65dc5c554b08ad949fc6f375',
        code: 'plt_wushu_punch',
        __v: 0,
        bonus_types: [],
        category: 'Video Slots',
        createdAt: '2024-02-26T09:39:33.319Z',
        deprecation_date: null,
        developer: 'Playtech',
        enabled: true,
        features: [],
        fun_mode: true,
        hit_ratio: null,
        name: 'Wushu Punch',
        release_date: '2022-05-05',
        rtp: '95.99',
        themes: [],
        updatedAt: '2024-02-27T11:07:52.022Z',
        volatility: null
    },
    {
        _id: '65dc5c434b08ad949fc6cda4',
        code: 'qps_book_of_duat',
        __v: 0,
        bonus_types: [],
        category: 'Video Slots',
        createdAt: '2024-02-26T09:39:15.529Z',
        deprecation_date: null,
        developer: 'Quickspin',
        enabled: true,
        features: ['Bonus Game', 'Bonus Buy', 'In-game Freespins', 'In-game Progress'],
        fun_mode: true,
        hit_ratio: null,
        name: 'Book of Duat',
        release_date: '2021-11-09',
        rtp: '94.19',
        themes: ['Adventure'],
        updatedAt: '2024-02-27T11:07:33.626Z',
        volatility: 4
    },
    {
        _id: '65dc5c8e4b08ad949fc771a2',
        code: 'pgs_leprechaun_riches',
        __v: 0,
        bonus_types: ['free_bets', 'free_money'],
        category: 'Video Slots',
        createdAt: '2024-02-26T09:40:29.967Z',
        deprecation_date: null,
        developer: 'PGSoft',
        enabled: true,
        features: [],
        fun_mode: false,
        hit_ratio: null,
        name: 'Leprechaun Riches',
        release_date: null,
        rtp: '97.35',
        themes: [],
        updatedAt: '2024-02-27T11:08:47.987Z',
        volatility: 3
    },
    {
        _id: '65dc5cc14b08ad949fc7e559',
        code: 'sms_jetx',
        __v: 0,
        bonus_types: [],
        category: 'Crash Games',
        createdAt: '2024-02-26T09:41:21.559Z',
        deprecation_date: null,
        developer: 'Smartsoft Gaming',
        enabled: true,
        features: [],
        fun_mode: true,
        hit_ratio: null,
        name: 'JetX',
        release_date: '2018-03-03',
        rtp: '97.0',
        themes: [],
        updatedAt: '2024-02-27T11:09:40.448Z',
        volatility: null
    },
    {
        _id: '65dc5bf74b08ad949fc6299d',
        code: 'ntn_aloha_christmas',
        __v: 0,
        bonus_types: ['free_bets'],
        category: 'Video Slots',
        createdAt: '2024-02-26T09:37:59.854Z',
        deprecation_date: null,
        developer: 'NetEnt',
        enabled: true,
        features: [],
        fun_mode: true,
        hit_ratio: null,
        name: 'Aloha! Christmas',
        release_date: null,
        rtp: null,
        themes: [],
        updatedAt: '2024-02-27T11:15:13.238Z',
        volatility: null
    },
    {
        _id: '65dc5cc84b08ad949fc7f3f4',
        code: 'avx_nft_aviatrix',
        __v: 0,
        bonus_types: [],
        category: 'Crash Games',
        createdAt: '2024-02-26T09:41:28.289Z',
        deprecation_date: null,
        developer: 'Aviatrix',
        enabled: true,
        features: [],
        fun_mode: true,
        hit_ratio: null,
        name: 'NFT Aviatrix',
        release_date: null,
        rtp: '97',
        themes: [],
        updatedAt: '2024-02-27T11:09:47.699Z',
        volatility: null
    },
    {
        _id: '65dc5be54b08ad949fc605a7',
        code: 'rtg_4squad',
        __v: 0,
        bonus_types: ['free_bets'],
        category: 'Video Slots',
        createdAt: '2024-02-26T09:37:41.881Z',
        deprecation_date: null,
        developer: 'Red Tiger',
        enabled: true,
        features: [],
        fun_mode: true,
        hit_ratio: null,
        name: '4Squad',
        release_date: null,
        rtp: null,
        themes: [],
        updatedAt: '2024-02-27T11:14:51.725Z',
        volatility: null
    },
    {
        _id: '65dc5bd54b08ad949fc5e244',
        code: 'evo_fan_tan',
        __v: 0,
        bonus_types: ['free_money'],
        category: 'Live Dealer',
        createdAt: '2024-02-26T09:37:25.828Z',
        deprecation_date: null,
        developer: 'Evolution Gaming',
        enabled: true,
        features: [],
        fun_mode: false,
        hit_ratio: null,
        name: 'Fan Tan',
        release_date: null,
        rtp: '98.75',
        themes: [],
        updatedAt: '2024-02-27T11:14:36.156Z',
        volatility: null
    },
    {
        _id: '65dc5c234b08ad949fc686e2',
        code: 'pgp_extra_juicy_megaways',
        __v: 0,
        bonus_types: ['free_bets'],
        category: 'Video Slots',
        createdAt: '2024-02-26T09:38:42.973Z',
        deprecation_date: null,
        developer: 'Pragmatic Play',
        enabled: true,
        features: ['Megaways'],
        fun_mode: true,
        hit_ratio: null,
        name: 'Extra Juicy Megaways',
        release_date: '2022-02-15',
        rtp: '96.42',
        themes: [],
        updatedAt: '2024-02-27T11:07:01.223Z',
        volatility: null
    }
];

export const homeCasinoList = [
    {
        _id: '65dc5cae4b08ad949fc7bc7c',
        code: 'spn_dark_wolf',
        __v: 0,
        bonus_types: ['free_bets'],
        category: 'Video Slots',
        createdAt: '2024-02-26T09:41:02.923Z',
        deprecation_date: null,
        developer: 'Spinomenal',
        enabled: true,
        features: ['Bonus Buy', 'Feature Choice'],
        fun_mode: true,
        hit_ratio: '26.0',
        name: 'Dark Wolf',
        release_date: '2022-06-30',
        rtp: '92.16',
        themes: [],
        updatedAt: '2024-02-27T11:09:21.608Z',
        volatility: 3
    },
    {
        _id: '65dc5caf4b08ad949fc7bca1',
        code: 'spn_the_ankh_protector',
        __v: 0,
        bonus_types: ['free_bets'],
        category: 'Video Slots',
        createdAt: '2024-02-26T09:41:03.015Z',
        deprecation_date: null,
        developer: 'Spinomenal',
        enabled: true,
        features: ['Bonus Buy', 'In-game Freespins'],
        fun_mode: true,
        hit_ratio: '22.0',
        name: 'The Ankh Protector',
        release_date: '2022-06-27',
        rtp: '92.06',
        themes: [],
        updatedAt: '2024-02-27T11:09:21.706Z',
        volatility: 3
    },
    {
        _id: '65dc5caf4b08ad949fc7bcdb',
        code: 'spn_tennis_champions',
        __v: 0,
        bonus_types: ['free_bets'],
        category: 'Video Slots',
        createdAt: '2024-02-26T09:41:03.107Z',
        deprecation_date: null,
        developer: 'Spinomenal',
        enabled: false,
        features: [],
        fun_mode: true,
        hit_ratio: '9.5',
        name: 'Tennis Champions',
        release_date: '2022-06-20',
        rtp: '90.94',
        themes: [],
        updatedAt: '2024-02-27T11:09:21.812Z',
        volatility: 3
    },
    {
        _id: '65dc5caf4b08ad949fc7bd0a',
        code: 'spn_kupala_ii',
        __v: 0,
        bonus_types: ['free_bets'],
        category: 'Video Slots',
        createdAt: '2024-02-26T09:41:03.201Z',
        deprecation_date: null,
        developer: 'Spinomenal',
        enabled: true,
        features: [],
        fun_mode: true,
        hit_ratio: '1.7',
        name: 'Kupala II',
        release_date: '2022-06-16',
        rtp: '92.09',
        themes: [],
        updatedAt: '2024-02-27T11:09:21.919Z',
        volatility: 3
    },
    {
        _id: '65dc5caf4b08ad949fc7bd57',
        code: 'spn_1_reel_panther',
        __v: 0,
        bonus_types: ['free_bets'],
        category: 'Video Slots',
        createdAt: '2024-02-26T09:41:03.293Z',
        deprecation_date: null,
        developer: 'Spinomenal',
        enabled: true,
        features: [],
        fun_mode: true,
        hit_ratio: '18.0',
        name: '1 Reel Panther',
        release_date: '2022-06-07',
        rtp: '91.55',
        themes: [],
        updatedAt: '2024-02-27T11:09:22.019Z',
        volatility: 4
    },
    {
        _id: '65dc5caf4b08ad949fc7bd6d',
        code: 'spn_book_of_demi_gods_iv',
        __v: 0,
        bonus_types: ['free_bets'],
        category: 'Video Slots',
        createdAt: '2024-02-26T09:41:03.384Z',
        deprecation_date: null,
        developer: 'Spinomenal',
        enabled: true,
        features: ['Bonus Buy', 'In-game Freespins'],
        fun_mode: true,
        hit_ratio: '1.1',
        name: 'Book Of Demi Gods IV',
        release_date: '2022-06-01',
        rtp: '91.71',
        themes: [],
        updatedAt: '2024-02-27T11:09:22.112Z',
        volatility: 4
    },
    {
        _id: '65dc5caf4b08ad949fc7bd9f',
        code: 'spn_book_of_panther',
        __v: 0,
        bonus_types: ['free_bets'],
        category: 'Video Slots',
        createdAt: '2024-02-26T09:41:03.478Z',
        deprecation_date: null,
        developer: 'Spinomenal',
        enabled: true,
        features: ['Bonus Buy', 'In-game Freespins'],
        fun_mode: true,
        hit_ratio: '1.1',
        name: 'Book Of Panther',
        release_date: '2022-05-30',
        rtp: '91.71',
        themes: [],
        updatedAt: '2024-02-27T11:09:22.204Z',
        volatility: 4
    },
    {
        _id: '65dc5caf4b08ad949fc7bdd1',
        code: 'spn_1_reel_joker',
        __v: 0,
        bonus_types: ['free_bets'],
        category: 'Video Slots',
        createdAt: '2024-02-26T09:41:03.569Z',
        deprecation_date: null,
        developer: 'Spinomenal',
        enabled: true,
        features: [],
        fun_mode: true,
        hit_ratio: '18.0',
        name: '1 Reel Joker',
        release_date: '2022-05-19',
        rtp: '91.55',
        themes: [],
        updatedAt: '2024-02-27T11:09:22.296Z',
        volatility: 4
    },
    {
        _id: '65dc5caf4b08ad949fc7bdff',
        code: 'spn_glowing_fruits',
        __v: 0,
        bonus_types: ['free_bets'],
        category: 'Video Slots',
        createdAt: '2024-02-26T09:41:03.661Z',
        deprecation_date: null,
        developer: 'Spinomenal',
        enabled: true,
        features: ['Bonus Game', 'In-game Jackpot'],
        fun_mode: true,
        hit_ratio: '15.0',
        name: 'Glowing Fruits',
        release_date: '2022-05-16',
        rtp: '92.06',
        themes: [],
        updatedAt: '2024-02-27T11:09:22.387Z',
        volatility: 3
    },
    {
        _id: '65dc5caf4b08ad949fc7be2d',
        code: 'spn_wolf_fang__iron_wolf',
        __v: 0,
        bonus_types: ['free_bets'],
        category: 'Video Slots',
        createdAt: '2024-02-26T09:41:03.756Z',
        deprecation_date: null,
        developer: 'Spinomenal',
        enabled: true,
        features: ['Bonus Buy', 'Feature Choice'],
        fun_mode: true,
        hit_ratio: '7.2',
        name: 'Wolf Fang - Iron Wolf',
        release_date: '2022-05-09',
        rtp: '91.54',
        themes: [],
        updatedAt: '2024-02-27T11:09:22.479Z',
        volatility: 3
    },
    {
        _id: '65dc5caf4b08ad949fc7be75',
        code: 'spn_savannahs_queen',
        __v: 0,
        bonus_types: ['free_bets'],
        category: 'Video Slots',
        createdAt: '2024-02-26T09:41:03.847Z',
        deprecation_date: null,
        developer: 'Spinomenal',
        enabled: true,
        features: ['Bonus Buy', 'In-game Freespins', 'In-game Jackpot'],
        fun_mode: true,
        hit_ratio: '15.0',
        name: "Savannah's Queen",
        release_date: '2022-04-26',
        rtp: '90.65',
        themes: [],
        updatedAt: '2024-02-27T11:09:22.572Z',
        volatility: 3
    },
    {
        _id: '65dc5caf4b08ad949fc7bebf',
        code: 'spn_1_reel_demi_god_ii',
        __v: 0,
        bonus_types: ['free_bets'],
        category: 'Video Slots',
        createdAt: '2024-02-26T09:41:03.938Z',
        deprecation_date: null,
        developer: 'Spinomenal',
        enabled: true,
        features: [],
        fun_mode: true,
        hit_ratio: '18.0',
        name: '1 Reel Demi God II',
        release_date: '2022-04-14',
        rtp: '91.55',
        themes: [],
        updatedAt: '2024-02-27T11:09:22.664Z',
        volatility: 4
    },
    {
        _id: '65dc5cb04b08ad949fc7beed',
        code: 'spn_easter_gifts',
        __v: 0,
        bonus_types: ['free_bets'],
        category: 'Video Slots',
        createdAt: '2024-02-26T09:41:04.029Z',
        deprecation_date: null,
        developer: 'Spinomenal',
        enabled: true,
        features: [],
        fun_mode: true,
        hit_ratio: '1.7',
        name: 'Easter Gifts',
        release_date: '2022-04-07',
        rtp: '92.09',
        themes: [],
        updatedAt: '2024-02-27T11:09:22.756Z',
        volatility: 4
    },
    {
        _id: '65dc5cb04b08ad949fc7bf2d',
        code: 'spn_book_of_sirens',
        __v: 0,
        bonus_types: ['free_bets'],
        category: 'Video Slots',
        createdAt: '2024-02-26T09:41:04.125Z',
        deprecation_date: null,
        developer: 'Spinomenal',
        enabled: true,
        features: ['Bonus Buy', 'In-game Freespins'],
        fun_mode: true,
        hit_ratio: '1.1',
        name: 'Book of Sirens',
        release_date: '2022-04-04',
        rtp: '91.71',
        themes: [],
        updatedAt: '2024-02-27T11:09:22.849Z',
        volatility: 4
    },
    {
        _id: '65dc5cb04b08ad949fc7bf57',
        code: 'spn_mammoth_rampage',
        __v: 0,
        bonus_types: ['free_bets'],
        category: 'Video Slots',
        createdAt: '2024-02-26T09:41:04.217Z',
        deprecation_date: null,
        developer: 'Spinomenal',
        enabled: true,
        features: ['Bonus Buy', 'In-game Jackpot', 'In-game Freespins'],
        fun_mode: true,
        hit_ratio: '8.5',
        name: 'Mammoth Rampage',
        release_date: '2022-03-31',
        rtp: '92.04',
        themes: [],
        updatedAt: '2024-02-27T11:09:22.942Z',
        volatility: 4
    },
    {
        _id: '65dc5cb04b08ad949fc7bf76',
        code: 'spn_egyptian_ways',
        __v: 0,
        bonus_types: ['free_bets'],
        category: 'Video Slots',
        createdAt: '2024-02-26T09:41:04.311Z',
        deprecation_date: null,
        developer: 'Spinomenal',
        enabled: true,
        features: ['Bonus Buy', 'In-game Freespins'],
        fun_mode: true,
        hit_ratio: '9.6',
        name: 'Egyptian Ways',
        release_date: '2022-03-21',
        rtp: '92.24',
        themes: [],
        updatedAt: '2024-02-27T11:09:23.034Z',
        volatility: 3
    },
    {
        _id: '65dc5cb04b08ad949fc7bfa1',
        code: 'spn_1_reel_patrick',
        __v: 0,
        bonus_types: ['free_bets'],
        category: 'Video Slots',
        createdAt: '2024-02-26T09:41:04.402Z',
        deprecation_date: null,
        developer: 'Spinomenal',
        enabled: true,
        features: [],
        fun_mode: true,
        hit_ratio: '18.0',
        name: '1 Reel Patrick',
        release_date: '2022-03-15',
        rtp: '91.55',
        themes: [],
        updatedAt: '2024-02-27T11:09:23.131Z',
        volatility: 4
    },
    {
        _id: '65dc5cb04b08ad949fc7bfc8',
        code: 'spn_patricks_collection_40_lines',
        __v: 0,
        bonus_types: ['free_bets'],
        category: 'Video Slots',
        createdAt: '2024-02-26T09:41:04.495Z',
        deprecation_date: null,
        developer: 'Spinomenal',
        enabled: true,
        features: [],
        fun_mode: true,
        hit_ratio: '1.7',
        name: "Patrick's Collection 40 Lines",
        release_date: '2022-03-10',
        rtp: '92.09',
        themes: [],
        updatedAt: '2024-02-27T11:09:23.224Z',
        volatility: 2
    },
    {
        _id: '65dc5cb04b08ad949fc7bff9',
        code: 'spn_patricks_collection_30_lines',
        __v: 0,
        bonus_types: ['free_bets'],
        category: 'Video Slots',
        createdAt: '2024-02-26T09:41:04.587Z',
        deprecation_date: null,
        developer: 'Spinomenal',
        enabled: true,
        features: [],
        fun_mode: true,
        hit_ratio: '1.7',
        name: "Patrick's Collection 30 Lines",
        release_date: '2022-03-10',
        rtp: '92.09',
        themes: [],
        updatedAt: '2024-02-27T11:09:23.315Z',
        volatility: 2
    },
    {
        _id: '65dc5cb04b08ad949fc7c018',
        code: 'spn_patricks_collection_20_lines',
        __v: 0,
        bonus_types: ['free_bets'],
        category: 'Video Slots',
        createdAt: '2024-02-26T09:41:04.678Z',
        deprecation_date: null,
        developer: 'Spinomenal',
        enabled: true,
        features: [],
        fun_mode: true,
        hit_ratio: '1.7',
        name: "Patrick's Collection 20 Lines",
        release_date: '2022-03-10',
        rtp: '92.09',
        themes: [],
        updatedAt: '2024-02-27T11:09:23.407Z',
        volatility: 3
    }
];

export const homeLiveCasino = [
    {
        _id: '65dc5c014b08ad949fc63e69',
        code: 'ezg_blackjack_gold_5',
        __v: 0,
        bonus_types: [],
        category: 'Live Blackjack',
        createdAt: '2024-02-26T09:38:09.811Z',
        deprecation_date: null,
        developer: 'Ezugi',
        enabled: true,
        features: [''],
        fun_mode: false,
        hit_ratio: null,
        name: 'Blackjack Gold 5',
        release_date: null,
        rtp: null,
        themes: [''],
        updatedAt: '2024-02-27T11:15:24.409Z',
        volatility: null
    },
    {
        _id: '65dc5c014b08ad949fc63e8b',
        code: 'ezg_blackjack_gold_4',
        __v: 0,
        bonus_types: [],
        category: 'Live Blackjack',
        createdAt: '2024-02-26T09:38:09.899Z',
        deprecation_date: null,
        developer: 'Ezugi',
        enabled: true,
        features: [''],
        fun_mode: false,
        hit_ratio: null,
        name: 'Blackjack Gold 4',
        release_date: null,
        rtp: null,
        themes: [''],
        updatedAt: '2024-02-27T11:15:24.499Z',
        volatility: null
    },
    {
        _id: '65dc5c024b08ad949fc63efc',
        code: 'ezg_blackjack_1',
        __v: 0,
        bonus_types: [],
        category: 'Live Blackjack',
        createdAt: '2024-02-26T09:38:10.086Z',
        deprecation_date: null,
        developer: 'Ezugi',
        enabled: true,
        features: [''],
        fun_mode: false,
        hit_ratio: null,
        name: 'Blackjack 1',
        release_date: null,
        rtp: null,
        themes: [''],
        updatedAt: '2024-02-27T11:15:24.679Z',
        volatility: null
    },
    {
        _id: '65dc5c024b08ad949fc63f36',
        code: 'ezg_blackjack_gold_3',
        __v: 0,
        bonus_types: [],
        category: 'Live Blackjack',
        createdAt: '2024-02-26T09:38:10.193Z',
        deprecation_date: null,
        developer: 'Ezugi',
        enabled: true,
        features: [''],
        fun_mode: false,
        hit_ratio: null,
        name: 'Blackjack Gold 3',
        release_date: null,
        rtp: null,
        themes: [''],
        updatedAt: '2024-02-27T11:15:24.770Z',
        volatility: null
    },
    {
        _id: '65dc5c024b08ad949fc63f54',
        code: 'ezg_blackjack_gold_1',
        __v: 0,
        bonus_types: [],
        category: 'Live Blackjack',
        createdAt: '2024-02-26T09:38:10.305Z',
        deprecation_date: null,
        developer: 'Ezugi',
        enabled: true,
        features: [''],
        fun_mode: false,
        hit_ratio: null,
        name: 'Blackjack Gold 1',
        release_date: null,
        rtp: null,
        themes: [''],
        updatedAt: '2024-02-27T11:15:24.867Z',
        volatility: null
    },
    {
        _id: '65dc5c024b08ad949fc63f71',
        code: 'ezg_blackjack_7',
        __v: 0,
        bonus_types: [],
        category: 'Live Blackjack',
        createdAt: '2024-02-26T09:38:10.414Z',
        deprecation_date: null,
        developer: 'Ezugi',
        enabled: false,
        features: [],
        fun_mode: false,
        hit_ratio: null,
        name: 'Blackjack 7',
        release_date: null,
        rtp: null,
        themes: [],
        updatedAt: '2024-02-27T11:15:24.963Z',
        volatility: null
    },
    {
        _id: '65dc5c024b08ad949fc63fc4',
        code: 'ezg_blackjack_gold_6',
        __v: 0,
        bonus_types: [],
        category: 'Live Blackjack',
        createdAt: '2024-02-26T09:38:10.526Z',
        deprecation_date: null,
        developer: 'Ezugi',
        enabled: true,
        features: [''],
        fun_mode: false,
        hit_ratio: null,
        name: 'Blackjack Gold 6',
        release_date: null,
        rtp: null,
        themes: [''],
        updatedAt: '2024-02-27T11:15:25.060Z',
        volatility: null
    },
    {
        _id: '65dc5c024b08ad949fc6401f',
        code: 'ezg_diamond_vip_blackjack',
        __v: 0,
        bonus_types: [],
        category: 'Live Blackjack',
        createdAt: '2024-02-26T09:38:10.793Z',
        deprecation_date: null,
        developer: 'Ezugi',
        enabled: true,
        features: [''],
        fun_mode: false,
        hit_ratio: null,
        name: 'Diamond VIP Blackjack',
        release_date: null,
        rtp: null,
        themes: [''],
        updatedAt: '2024-02-27T11:15:25.246Z',
        volatility: null
    },
    {
        _id: '65dc5c024b08ad949fc64043',
        code: 'ezg_italian_blackjack',
        __v: 0,
        bonus_types: [],
        category: 'Live Blackjack',
        createdAt: '2024-02-26T09:38:10.880Z',
        deprecation_date: '2024-02-22',
        developer: 'Ezugi',
        enabled: false,
        features: [],
        fun_mode: false,
        hit_ratio: null,
        name: 'Italian Blackjack',
        release_date: null,
        rtp: '99.5',
        themes: [],
        updatedAt: '2024-02-27T11:15:25.335Z',
        volatility: null
    },
    {
        _id: '65dc5c034b08ad949fc6407a',
        code: 'ezg_rumba_blackjack_1',
        __v: 0,
        bonus_types: [],
        category: 'Live Blackjack',
        createdAt: '2024-02-26T09:38:10.971Z',
        deprecation_date: null,
        developer: 'Ezugi',
        enabled: true,
        features: [''],
        fun_mode: false,
        hit_ratio: null,
        name: 'Rumba Blackjack 1',
        release_date: null,
        rtp: null,
        themes: [''],
        updatedAt: '2024-02-27T11:15:25.424Z',
        volatility: null
    },
    {
        _id: '65dc5c034b08ad949fc640b2',
        code: 'ezg_rumba_blackjack_2',
        __v: 0,
        bonus_types: [],
        category: 'Live Blackjack',
        createdAt: '2024-02-26T09:38:11.076Z',
        deprecation_date: '2022-08-04',
        developer: 'Ezugi',
        enabled: false,
        features: [],
        fun_mode: false,
        hit_ratio: null,
        name: 'Rumba Blackjack 2',
        release_date: null,
        rtp: null,
        themes: [],
        updatedAt: '2024-02-27T11:15:25.519Z',
        volatility: null
    },
    {
        _id: '65dc5c034b08ad949fc640dd',
        code: 'ezg_rumba_blackjack_3',
        __v: 0,
        bonus_types: [],
        category: 'Live Blackjack',
        createdAt: '2024-02-26T09:38:11.165Z',
        deprecation_date: '2022-08-04',
        developer: 'Ezugi',
        enabled: false,
        features: [],
        fun_mode: false,
        hit_ratio: null,
        name: 'Rumba Blackjack 3',
        release_date: null,
        rtp: null,
        themes: [],
        updatedAt: '2024-02-27T11:15:25.609Z',
        volatility: null
    },
    {
        _id: '65dc5c034b08ad949fc640fd',
        code: 'ezg_rumba_blackjack_4',
        __v: 0,
        bonus_types: [],
        category: 'Live Blackjack',
        createdAt: '2024-02-26T09:38:11.254Z',
        deprecation_date: null,
        developer: 'Ezugi',
        enabled: true,
        features: [''],
        fun_mode: false,
        hit_ratio: null,
        name: 'Rumba Blackjack 4',
        release_date: null,
        rtp: null,
        themes: [''],
        updatedAt: '2024-02-27T11:15:25.700Z',
        volatility: null
    },
    {
        _id: '65dc5c034b08ad949fc6410f',
        code: 'ezg_turkish_blackjack',
        __v: 0,
        bonus_types: [],
        category: 'Live Blackjack',
        createdAt: '2024-02-26T09:38:11.343Z',
        deprecation_date: null,
        developer: 'Ezugi',
        enabled: true,
        features: [''],
        fun_mode: false,
        hit_ratio: null,
        name: 'Turkish Blackjack',
        release_date: null,
        rtp: null,
        themes: [''],
        updatedAt: '2024-02-27T11:15:25.789Z',
        volatility: null
    },
    {
        _id: '65dc5c034b08ad949fc64133',
        code: 'ezg_turkce_blackjack_1',
        __v: 0,
        bonus_types: [],
        category: 'Live Blackjack',
        createdAt: '2024-02-26T09:38:11.431Z',
        deprecation_date: null,
        developer: 'Ezugi',
        enabled: true,
        features: [''],
        fun_mode: false,
        hit_ratio: null,
        name: 'Turkce Blackjack 1',
        release_date: null,
        rtp: null,
        themes: [''],
        updatedAt: '2024-02-27T11:15:25.878Z',
        volatility: null
    },
    {
        _id: '65dc5c034b08ad949fc64177',
        code: 'ezg_turkce_blackjack_2',
        __v: 0,
        bonus_types: [],
        category: 'Live Blackjack',
        createdAt: '2024-02-26T09:38:11.525Z',
        deprecation_date: null,
        developer: 'Ezugi',
        enabled: true,
        features: [''],
        fun_mode: false,
        hit_ratio: null,
        name: 'Turkce Blackjack 2',
        release_date: null,
        rtp: null,
        themes: [''],
        updatedAt: '2024-02-27T11:15:25.968Z',
        volatility: null
    },
    {
        _id: '65dc5c034b08ad949fc641c6',
        code: 'ezg_white_russian',
        __v: 0,
        bonus_types: [],
        category: 'Live Blackjack',
        createdAt: '2024-02-26T09:38:11.616Z',
        deprecation_date: null,
        developer: 'Ezugi',
        enabled: true,
        features: [],
        fun_mode: false,
        hit_ratio: null,
        name: 'Russian Blackjack 1',
        release_date: null,
        rtp: null,
        themes: [],
        updatedAt: '2024-02-27T11:15:26.059Z',
        volatility: null
    },
    {
        _id: '65dc5c034b08ad949fc641f7',
        code: 'ezg_black_russian',
        __v: 0,
        bonus_types: [],
        category: 'Live Blackjack',
        createdAt: '2024-02-26T09:38:11.705Z',
        deprecation_date: null,
        developer: 'Ezugi',
        enabled: true,
        features: [],
        fun_mode: false,
        hit_ratio: null,
        name: 'Russian Blackjack 2',
        release_date: null,
        rtp: null,
        themes: [],
        updatedAt: '2024-02-27T11:15:26.149Z',
        volatility: null
    }
];

export const baseRedirectURL = '/sports';

export const fPayBankList = [
    {
        currency: 'MYR',
        bank_name: 'MAYBANK',
        id: '246'
    },
    {
        currency: 'MYR',
        bank_name: 'CIMB',
        id: '261'
    },
    {
        currency: 'MYR',
        bank_name: 'HLB',
        id: '264'
    },
    {
        currency: 'MYR',
        bank_name: 'PUBLIC BANK',
        id: '273'
    },
    {
        currency: 'MYR',
        bank_name: 'RHB',
        id: '274'
    },
    {
        currency: 'MYR',
        bank_name: 'AMBANK',
        id: '250'
    },
    {
        currency: 'MYR',
        bank_name: 'Standard Chartered Bank',
        id: '275'
    },
    {
        currency: 'MYR',
        bank_name: 'BANK ISLAM',
        id: '251'
    },
    {
        currency: 'MYR',
        bank_name: 'BSN',
        id: '257'
    },
    {
        currency: 'MYR',
        bank_name: 'AI-RAHJI BANK',
        id: '248'
    },
    {
        currency: 'MYR',
        bank_name: 'UOB',
        id: '277'
    },
    {
        currency: 'MYR',
        bank_name: 'HSBC',
        id: '265'
    },
    {
        currency: 'MYR',
        bank_name: 'ALIIANCE BANK',
        id: '249'
    },
    {
        currency: 'MYR',
        bank_name: 'CITI BANK',
        id: '262'
    },
    {
        currency: 'MYR',
        bank_name: 'AFFIN BANK',
        id: '247'
    },
    {
        currency: 'MYR',
        bank_name: 'Bank Rakyat',
        id: '252'
    },
    {
        currency: 'MYR',
        bank_name: 'TOUCH N GO',
        id: '278'
    },
    {
        currency: 'MYR',
        bank_name: 'GXBANK',
        id: '279'
    }
];
