import { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Drawer from '@mui/material/Drawer';
import ListItemButton from '@mui/material/ListItemButton';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import Iconify from './iconify';

import useAuth from 'hooks/useAuth';
import useSettings from 'hooks/useSettings';
import useResponsive from 'hooks/useResponsive';
import { useRouter } from 'hooks/use-router';
import { useBoolean } from 'hooks/use-boolean';
import { useSnackbar } from 'notistack';

type listItemProps = {
    order: number;
    title: string;
    link: string;
    icon: any;
};

const MobileMenu = () => {
    const isMobile = useResponsive('down', 'sm');
    const router = useRouter();
    const { isLogined, user, logout } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    const { config, onChangePasswordModal, onChangeLoginModal } = useSettings();

    const openMenu = useBoolean();

    const [active, setActive] = useState({
        home: false,
        casino: false,
        sports: false,
        menu: false
    });

    const location = useLocation();

    const goRouter = (link: string) => {
        openMenu.onFalse();
        router.push(link);
    };

    const handlePassword = () => {
        openMenu.onFalse();
        onChangePasswordModal(true);
    };

    const handleLogout = () => {
        try {
            openMenu.onFalse();
            logout();
        } catch (error: any) {
            const message = typeof error === 'string' ? error : error.message;
            enqueueSnackbar(message, { variant: 'error' });
        }
    };

    const dropList = useMemo(() => {
        if (config) {
            let array: listItemProps[] = [
                {
                    title: 'profile',
                    order: 0,
                    link: '/profile',
                    icon: <Icon icon="mingcute:user-4-fill" width="24" height="24" />
                },
                {
                    order: 3,
                    title: 'account statement',
                    link: '/reports/account-statement',
                    icon: <Icon icon="game-icons:receive-money" width="24" height="24" />
                },
                {
                    order: 4,
                    title: 'game report',
                    link: '/reports/profit-loss',
                    icon: <Icon icon="octicon:log-16" width="24" height="24" />
                },
                {
                    order: 6,
                    title: 'my bets',
                    link: '/reports/mybets',
                    icon: <Icon icon="mdi:book-alphabet" width="24" height="24" />
                },
                {
                    order: 7,
                    title: 'Chip Settings',
                    link: '/setting/stake',
                    icon: <Icon icon="mdi:poker-chip" width="24" height="24" />
                }
                // {
                //     order: 8,
                //     title: 'IP log',
                //     link: '/reports/login-history',
                //     icon: <Icon icon="solar:document-text-bold-duotone" width="24" height="24" />
                // }
            ];
            if (config.IsDepositAllow) {
                array.push({
                    order: 1,
                    title: 'deposit',
                    link: '/payment/deposit',
                    icon: <Icon icon="mingcute:bank-fill" width="24" height="24" />
                });
            }
            if (config.IsWithdrawalAllow) {
                array.push({
                    order: 2,
                    title: 'withdraw',
                    link: '/payment/withdraw',
                    icon: <Icon icon="maki:bank" width="24" height="24" />
                });
            }
            if (config.type === 'B2C') {
                array.push({
                    order: 5,
                    title: 'Bonus',
                    link: '/reports/bonus',
                    icon: <Icon icon="fa6-solid:gift" width="24" height="24" />
                });
                array.push({
                    order: 7,
                    title: 'Deposit report',
                    link: '/reports/deposit',
                    icon: <Icon icon="fa6-solid:money-bill-transfer" width="24" height="24" />
                });
                array.push({
                    order: 7,
                    title: 'Withdraw report',
                    link: '/reports/withdraw',
                    icon: <Icon icon="fa6-solid:money-bill-transfer" width="24" height="24" />
                });
            }
            return array.sort((a, b) => a.order - b.order);
        }
        return [];
    }, [config]);

    const whatsappLink = useMemo(() => {
        if (config && config.socials) {
            const wIndex = config.socials.findIndex((one) => one.name === 'whatsapp' && one.link);
            if (wIndex > -1) {
                return config.socials[wIndex].link;
            }
            return '';
        }
        return '';
    }, [config]);

    useEffect(() => {
        let routers = location.pathname.split('/');
        if (routers.includes('home')) {
            setActive({
                home: true,
                casino: false,
                sports: false,
                menu: false
            });
        } else if (routers.includes('sports')) {
            setActive({
                home: false,
                casino: false,
                sports: true,
                menu: false
            });
        } else if (routers.includes('casino')) {
            setActive({
                home: false,
                casino: true,
                sports: false,
                menu: false
            });
        }
    }, [location]);

    if (isMobile) {
        return (
            <>
                <Box sx={{ position: 'fixed', zIndex: 1298, bottom: 0, left: 0, right: 0, bgcolor: '#284864', px: 2 }}>
                    <Stack direction="row" justifyContent="space-between">
                        <IconButton onClick={() => goRouter('/home')}>
                            <Stack>
                                <Iconify
                                    icon="flowbite:home-solid"
                                    width={30}
                                    height={30}
                                    color={active.home ? 'yellow' : 'white'}
                                />
                                <Typography sx={{ fontSize: 10, color: active.home ? 'yellow' : 'white' }}>
                                    Home
                                </Typography>
                            </Stack>
                        </IconButton>
                        <IconButton onClick={() => goRouter('/sports')}>
                            <Stack>
                                <Iconify
                                    icon="fluent:sport-24-filled"
                                    width={30}
                                    height={30}
                                    color={active.sports ? 'yellow' : 'white'}
                                />
                                <Typography sx={{ fontSize: 10, color: active.sports ? 'yellow' : 'white' }}>
                                    Sports
                                </Typography>
                            </Stack>
                        </IconButton>
                        <IconButton sx={{ p: 0 }}>
                            {whatsappLink ? (
                                <Link to={whatsappLink} target="_blank">
                                    <Box
                                        sx={{
                                            p: 1,
                                            display: 'flex',
                                            borderRadius: 50,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            bgcolor: 'primary.light'
                                        }}
                                    >
                                        <Iconify icon="logos:whatsapp-icon" width={30} height={30} />
                                    </Box>
                                </Link>
                            ) : (
                                <Box
                                    sx={{
                                        p: 1,
                                        display: 'flex',
                                        borderRadius: 50,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        bgcolor: 'primary.light'
                                    }}
                                >
                                    <Iconify icon="logos:whatsapp-icon" width={30} height={30} />
                                </Box>
                            )}
                        </IconButton>
                        <IconButton onClick={() => goRouter('/casino')}>
                            <Stack>
                                <Iconify
                                    icon="mdi:casino-chip"
                                    width={30}
                                    height={30}
                                    color={active.casino ? 'yellow' : 'white'}
                                />
                                <Typography sx={{ fontSize: 10, color: active.casino ? 'yellow' : 'white' }}>
                                    Casino
                                </Typography>
                            </Stack>
                        </IconButton>
                        {isLogined ? (
                            <IconButton onClick={openMenu.onTrue}>
                                <Stack>
                                    <Iconify icon="mingcute:user-4-fill" width={30} height={30} color="white" />
                                    <Typography sx={{ fontSize: 10, color: 'white' }}>Profile</Typography>
                                </Stack>
                            </IconButton>
                        ) : (
                            <IconButton onClick={onChangeLoginModal}>
                                <Stack>
                                    <Iconify icon="solar:login-3-bold" width={30} height={30} color="white" />
                                    <Typography sx={{ fontSize: 10, color: 'white' }}>Sing In</Typography>
                                </Stack>
                            </IconButton>
                        )}
                    </Stack>
                </Box>
                {isLogined && user && (
                    <Drawer
                        anchor="right"
                        open={openMenu.value}
                        onClose={openMenu.onFalse}
                        ModalProps={{
                            keepMounted: true
                        }}
                        sx={{
                            zIndex: 1299
                        }}
                        PaperProps={{
                            sx: {
                                height: '100vh',
                                bgcolor: 'background.paper',
                                borderLeft: '1px dashed #919eab3d'
                            }
                        }}
                    >
                        <List
                            sx={{ width: '100%', px: 2 }}
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                            subheader={
                                <ListSubheader component="div" sx={{ display: 'flex', alignItems: 'center', my: 3 }}>
                                    <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'text.primary' }}>
                                        {user.username}
                                    </Typography>
                                </ListSubheader>
                            }
                        >
                            {dropList.map((item: listItemProps, index: number) => (
                                <ListItemButton
                                    onClick={() => goRouter(item.link)}
                                    key={index}
                                    sx={{
                                        minWidth: 200,
                                        '& .MuiListItemText-primary': {
                                            fontSize: 14,
                                            fontWeight: 600,
                                            color: 'text.secondary',
                                            textTransform: 'capitalize'
                                        },
                                        '& svg': {
                                            color: 'text.secondary'
                                        },
                                        '&:hover': {
                                            '& svg': {
                                                color: 'text.primary'
                                            },
                                            '& .MuiListItemText-primary': {
                                                color: 'text.primary'
                                            }
                                        }
                                    }}
                                >
                                    {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                                    <ListItemText primary={item.title} />
                                </ListItemButton>
                            ))}
                            <ListItemButton
                                onClick={handlePassword}
                                sx={{
                                    minWidth: 200,
                                    '& .MuiListItemText-primary': {
                                        fontSize: 14,
                                        fontWeight: 600,
                                        color: 'text.secondary',
                                        textTransform: 'capitalize'
                                    },
                                    '& svg': {
                                        color: 'text.secondary'
                                    },
                                    '&:hover': {
                                        '& svg': {
                                            color: 'text.primary'
                                        },
                                        '& .MuiListItemText-primary': {
                                            color: 'text.primary'
                                        }
                                    }
                                }}
                            >
                                <ListItemIcon>
                                    <Icon icon="solar:lock-password-bold-duotone" width="24" height="24" />
                                </ListItemIcon>
                                <ListItemText primary="Change Password" />
                            </ListItemButton>
                            <ListItemButton
                                onClick={handleLogout}
                                sx={{
                                    minWidth: 200,
                                    '& .MuiListItemText-primary': {
                                        fontSize: 14,
                                        fontWeight: 600,
                                        color: 'text.secondary',
                                        textTransform: 'capitalize'
                                    },
                                    '& svg': {
                                        color: 'text.secondary'
                                    },
                                    '&:hover': {
                                        '& svg': {
                                            color: 'text.primary'
                                        },
                                        '& .MuiListItemText-primary': {
                                            color: 'text.primary'
                                        }
                                    }
                                }}
                            >
                                <ListItemIcon>
                                    <Icon icon="solar:logout-3-bold" width="24" height="24" />
                                </ListItemIcon>
                                <ListItemText primary={'logout'} />
                            </ListItemButton>
                        </List>
                    </Drawer>
                )}
            </>
        );
    }

    return null;
};

export default MobileMenu;
