import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ButtonBase from '@mui/material/ButtonBase';
// static
import { headerList } from 'config/constant';
// hook
import useSettings from 'hooks/useSettings';
// util
import { ASSETS } from 'utils/axios';
import logoIcon from 'assets/img/logo/logo.png';
import UserPart from './UserPart';

const MobileHeader = () => {
    const { navbar, onChangeNav, config } = useSettings();

    return (
        <Stack
            component="header"
            sx={{ height: { sm: 130, xs: 'auto' }, overflow: 'hidden', bgcolor: 'background.paper' }}
        >
            <Container maxWidth="xl" sx={{ height: '100%' }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 1 }}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <IconButton sx={{ p: 0 }} onClick={onChangeNav}>
                            {navbar === 'close' ? (
                                <Icon icon="mdi:menu-close" color="#ffab00" width="30" height="30" />
                            ) : (
                                <Icon icon="ic:twotone-menu-open" color="#ffab00" width="30" height="30" />
                            )}
                        </IconButton>
                        <RouterLink to="/home">
                            <Box
                                component="img"
                                src={config && config.domain.logo ? ASSETS(config?.domain.logo) : logoIcon}
                                alt="logo"
                                sx={{ mr: 1, maxWidth: { sm: 170, xs: 130 }, maxHeight: 40 }}
                            />
                        </RouterLink>
                    </Stack>
                    <UserPart />
                </Stack>
            </Container>
            <Box sx={{ bgcolor: '#284864' }}>
                <Stack
                    direction="row"
                    flexWrap="nowrap"
                    alignItems="center"
                    spacing={0.3}
                    sx={{ py: 0.3, overflow: 'auto' }}
                >
                    {headerList.map((item: { title: string; icon: string; link: string }, i: number) => (
                        <ButtonBase
                            key={i}
                            color="inherit"
                            component={RouterLink}
                            to={item.link}
                            sx={{
                                px: 2,
                                py: 0.5,
                                flexGrow: 1,
                                flex: 1,
                                borderRadius: 1,
                                color: 'text.primary',
                                bgcolor: (theme) => ({ sm: theme.palette.background.paper, xs: 'transparent' }),
                                '&:hover': { bgcolor: (theme) => theme.palette.background.paper }
                            }}
                        >
                            <Stack alignItems="center">
                                <Box
                                    component="img"
                                    src={item.icon}
                                    alt="item"
                                    sx={{ width: { sm: 25, xs: 20 }, height: { sm: 25, xs: 20 } }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: 14,
                                        fontWeight: { sm: 600, xs: 500 },
                                        whiteSpace: 'nowrap',
                                        color: '#FFF'
                                    }}
                                >
                                    {item.title}
                                </Typography>
                            </Stack>
                        </ButtonBase>
                    ))}
                </Stack>
            </Box>
        </Stack>
    );
};

export default MobileHeader;
