// Mui
import Box from '@mui/material/Box';

// components
import Poker from './Poker';

const PokerGame = () => {
    return (
        <Box sx={{ pb: 4 }}>
            <Poker />
        </Box>
    );
};

export default PokerGame;
