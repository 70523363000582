import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
// static
import { headerList } from 'config/constant';
// hook
import useAuth from 'hooks/useAuth';
import useSettings from 'hooks/useSettings';
// type
import { HeaderItemProps } from 'types/sports';
//
import { ASSETS } from 'utils/axios';
//
import logoIcon from 'assets/img/logo/logo.png';
import UserPart from './UserPart';

const Header = () => {
    const { config } = useSettings();
    const { user, isLogined } = useAuth();

    const allowedList = useMemo(() => {
        if (isLogined && user && user.permissions) {
            return headerList.filter((e: HeaderItemProps) => !(user.permissions[e.title] === false));
        }
        return headerList;
    }, [isLogined, user, headerList]);
    return (
        <Stack component="header" sx={{ bgcolor: '#284864', height: { sm: 130, xs: 'auto' } }}>
            <Container maxWidth="xl" sx={{ height: '100%' }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" py={2}>
                    <RouterLink to="/home">
                        <Box
                            component="img"
                            src={config && config.domain.logo ? ASSETS(config?.domain.logo) : logoIcon}
                            alt="logo"
                            sx={{ maxWidth: { sm: 170, xs: 130 }, maxHeight: 35 }}
                        />
                    </RouterLink>
                    <UserPart />
                </Stack>
            </Container>
            <Box sx={{ bgcolor: { sm: '#00000054', xs: 'transparent' } }}>
                <Container maxWidth="xl" sx={{ px: { sm: 3, xs: 0 } }}>
                    <Stack
                        direction="row"
                        flexWrap="nowrap"
                        alignItems="center"
                        spacing={0.3}
                        sx={{ py: 0.3, overflow: 'auto' }}
                    >
                        {allowedList.map((item: { title: string; icon: string; link: string }, i: number) => (
                            <ButtonBase
                                key={i}
                                color="inherit"
                                component={RouterLink}
                                to={item.link}
                                sx={{
                                    px: 2,
                                    py: 0.5,
                                    flexGrow: 1,
                                    flex: 1,
                                    borderRadius: 1,
                                    color: 'text.primary',
                                    bgcolor: (theme) => ({ sm: theme.palette.background.paper, xs: 'transparent' }),
                                    '&:hover': { bgcolor: (theme) => theme.palette.background.paper }
                                }}
                            >
                                <Stack alignItems="center">
                                    <Box
                                        component="img"
                                        src={item.icon}
                                        alt="item"
                                        sx={{ width: { sm: 'auto', xs: 20 }, height: { sm: 25, xs: 20 } }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            fontWeight: { sm: 600, xs: 500 },
                                            whiteSpace: 'nowrap'
                                        }}
                                    >
                                        {item.title}
                                    </Typography>
                                </Stack>
                            </ButtonBase>
                        ))}
                    </Stack>
                </Container>
            </Box>
        </Stack>
    );
};

export default Header;
