import { useRoutes, Navigate } from 'react-router-dom';

import { lazy } from 'react';

// Bounding Import
import HomeLayout from 'layouts/HomeLayout';
import CasinoLayout from 'layouts/CasinoLayout';
import SportsLayout from 'layouts/SportsLayout';
import FullLayout from 'layouts/FullLayout';
import UserLayout from 'layouts/UserLayout';
import Loadable from 'components/Loadable';
import AuthGuard from './auth-guard';
import SportsGuard from './sports-guard';
import BetHistory from 'pages/User/BetHistory';
import Poker from 'pages/Poker';
import Spribe from 'pages/Spribe';
import SpribeGame from 'pages/Spribe/Game';

const Home = Loadable(lazy(() => import('pages/Home')));

const Sports = Loadable(lazy(() => import('pages/Sports')));
const SportsInplay = Loadable(lazy(() => import('pages/Sports/Inplay')));
const SportsLeague = Loadable(lazy(() => import('pages/Sports/League')));
const Match = Loadable(lazy(() => import('pages/Sports/Match')));

const Casino = Loadable(lazy(() => import('pages/Casino')));
const EzugiCasino = Loadable(lazy(() => import('pages/Casino/Ezugi')));
const EvolutionCasino = Loadable(lazy(() => import('pages/Casino/Evolution')));

const Promotion = Loadable(lazy(() => import('pages/Promotion')));

const Register = Loadable(lazy(() => import('pages/Auth/Register')));

const History = Loadable(lazy(() => import('pages/User/History')));
const MyBets = Loadable(lazy(() => import('pages/User/Mybets')));
const ProfitLoss = Loadable(lazy(() => import('pages/User/ProfitLoss')));
const AccountStatement = Loadable(lazy(() => import('pages/User/AccountStatement')));
const Bonus = Loadable(lazy(() => import('pages/User/Bonus')));
const DepositHistory = Loadable(lazy(() => import('pages/User/DepositHistory')));
const WithdrawHistory = Loadable(lazy(() => import('pages/User/WithdrawHistory')));
const LoginHistory = Loadable(lazy(() => import('pages/User/LoginHistory')));
const Deposit = Loadable(lazy(() => import('pages/User/Deposit')));
const WithdrawAndKyc = Loadable(lazy(() => import('pages/User/WithdrawAndKyc')));
const StakeSetting = Loadable(lazy(() => import('pages/User/StakeSetting')));
const Profile = Loadable(lazy(() => import('pages/User/Profile')));
const ForgotPassword = Loadable(lazy(() => import('pages/Auth/ForgotPassword')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([
        { path: '/', element: <Navigate to="/home" /> },
        { path: '*', element: <Navigate to="/home" /> },
        // Home
        {
            path: '',
            element: <HomeLayout />,
            children: [
                {
                    path: '/home',
                    element: <Home />
                }
            ]
        },
        // Auth
        {
            path: 'auth',
            element: <FullLayout />,
            children: [
                {
                    path: 'register',
                    element: <Register />
                },
                {
                    path: 'forgot-password',
                    element: <ForgotPassword />
                }
            ]
        },
        // Sports
        {
            path: 'sports',
            element: <SportsLayout />,
            children: [
                {
                    path: '',
                    element: <Sports />
                },
                {
                    path: 'inplay',
                    element: <SportsInplay />
                },
                {
                    path: ':sportId',
                    element: (
                        <SportsGuard>
                            <SportsLeague />
                        </SportsGuard>
                    )
                },
                {
                    path: ':sportId/:marketId',
                    element: (
                        <SportsGuard>
                            <Match />
                        </SportsGuard>
                    )
                }
            ]
        },
        // Casino
        {
            path: 'casino',
            element: <CasinoLayout />,
            children: [
                {
                    path: '',
                    element: <Casino />
                },
                {
                    path: 'ezugi',
                    element: <EzugiCasino />
                },
                {
                    path: 'evolution',
                    element: <EvolutionCasino />
                }
            ]
        },
        // Poker
        {
            path: 'poker',
            element: <CasinoLayout />,
            children: [
                {
                    path: '',
                    element: <Poker />
                }
            ]
        },
        // Spribe Aviator
        {
            path: 'spribe',
            element: <CasinoLayout />,
            children: [
                {
                    path: '',
                    element: <Spribe />
                },
                {
                    path: ':game',
                    element: <SpribeGame />
                }
            ]
        },
        // Promotion
        {
            path: 'promotion',
            element: <SportsLayout />,
            children: [
                {
                    path: '',
                    element: <Promotion />
                }
            ]
        },
        // User
        {
            path: 'reports',
            element: (
                <AuthGuard>
                    <UserLayout />
                </AuthGuard>
            ),
            children: [
                {
                    path: '',
                    element: <History />
                },
                {
                    path: 'mybets',
                    element: <MyBets />
                },
                {
                    path: 'profit-loss',
                    element: <ProfitLoss />
                },
                {
                    path: 'bets-history',
                    element: <BetHistory />
                },
                {
                    path: 'account-statement',
                    element: <AccountStatement />
                },
                {
                    path: 'bonus',
                    element: <Bonus />
                },
                {
                    path: 'deposit',
                    element: <DepositHistory />
                },
                {
                    path: 'withdraw',
                    element: <WithdrawHistory />
                },
                {
                    path: 'login-history',
                    element: <LoginHistory />
                }
            ]
        },
        {
            path: 'payment',
            element: (
                <AuthGuard>
                    <UserLayout />
                </AuthGuard>
            ),
            children: [
                {
                    path: 'deposit',
                    element: <Deposit />
                },
                {
                    path: 'withdraw',
                    element: <WithdrawAndKyc />
                }
            ]
        },
        {
            path: 'profile',
            element: (
                <AuthGuard>
                    <UserLayout />
                </AuthGuard>
            ),
            children: [
                {
                    path: '',
                    element: <Profile />
                }
            ]
        },
        {
            path: 'setting',
            element: (
                <AuthGuard>
                    <UserLayout />
                </AuthGuard>
            ),
            children: [
                {
                    path: 'stake',
                    element: <StakeSetting />
                }
            ]
        }
    ]);
}
