import FastMarquee from 'react-fast-marquee';
// @mui
import Box from '@mui/material/Box';
// hook
import useSettings from 'hooks/useSettings';
import { useMemo } from 'react';

const Marquee = () => {
    const { config } = useSettings();

    const marquee = useMemo(() => {
        if (config) {
            let marqueeData: string[] = [];
            if (config.marquee) {
                marqueeData.push(config.marquee);
            }
            if (config.marquee1) {
                marqueeData.push(config.marquee1);
            }
            return marqueeData;
        }
        return [];
    }, [config]);

    if (marquee.length) {
        return (
            <Box sx={{ bgcolor: '#284864', p: 0.5, borderRadius: 1, m: 1 }}>
                {marquee.map((text, i) => (
                    <FastMarquee key={i} direction="left" style={{ color: '#FFF' }}>
                        {text}
                    </FastMarquee>
                ))}
            </Box>
        );
    }
    return <Box sx={{ pt: 1 }} />;
};

export default Marquee;
