// @mui
import { GlobalStyles as MUIGlobalStyles } from '@mui/material';

// ----------------------------------------------------------------------

export default function GlobalStyles() {
    const inputGlobalStyles = (
        <MUIGlobalStyles
            styles={{
                '*': {
                    boxSizing: 'border-box'
                },
                html: {
                    margin: 0,
                    padding: 0,
                    width: '100%',
                    height: '100%',
                    WebkitOverflowScrolling: 'touch'
                },
                a: {
                    color: 'unset',
                    textDecoration: 'none'
                },
                body: {
                    margin: 0,
                    padding: 0,
                    width: '100%',
                    height: '100%'
                },
                '#root': {
                    width: '100%',
                    height: '100%'
                },
                input: {
                    '&[type=number]': {
                        MozAppearance: 'textfield',
                        '&::-webkit-outer-spin-button': {
                            margin: 0,
                            WebkitAppearance: 'none'
                        },
                        '&::-webkit-inner-spin-button': {
                            margin: 0,
                            WebkitAppearance: 'none'
                        }
                    }
                },
                img: {
                    display: 'block',
                    maxWidth: '100%'
                },
                ul: {
                    margin: 0,
                    padding: 0
                },
                '::-webkit-scrollbar': {
                    width: 10,
                    height: 0
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: '#1e2028'
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#3c3f4a',
                    borderRadius: '20px'
                },
                '.MuiDateRangeCalendar-root>div:first-of-type': {
                    display: 'none !important'
                },
                '.arrow_box_0': {
                    background: '#f6922b',
                    border: '2px solid #8b4601'
                },
                '.arrow_box_1': {
                    background: '#c2cad0',
                    border: '2px solid #626263'
                },
                '.arrow_box_2': {
                    background: '#ffb70a',
                    border: '2px solid #b17e06'
                },
                '.arrow_box_3': {
                    background: '#a9b1c7',
                    border: '2px solid #6d6d6d'
                },
                '.arrow_box_4': {
                    background: '#5f9cb9',
                    border: '2px solid #1385b9'
                },
                '.arrow_box': {
                    position: 'relative',
                    borderRadius: 4
                },
                '.apexcharts-tooltip': {
                    color: '#fff',
                    transform: 'translateX(10px) translateY(10px)',
                    overflow: 'visible!important',
                    whiteSpace: 'normal!important'
                },
                '.apexcharts-tooltip span': {
                    padding: '5px 10px',
                    display: 'inline-block'
                },
                '@keyframes drift': {
                    '12.5%': { backgroundImage: 'linear-gradient(to right, #f0f, #00f)' },
                    '25%': { backgroundImage: 'linear-gradient(to right, #00f, #0ff)' },
                    '37.5%': { backgroundImage: 'linear-gradient(to right, #0ff, #0f9)' },
                    '50%': { backgroundImage: 'linear-gradient(to right, #0f9, #0a0)' },
                    '62.5%': { backgroundImage: 'linear-gradient(to right, #0a0, #ff0)' },
                    '75%': { backgroundImage: 'linear-gradient(to right, #ff0, #fa0)' },
                    '87.5%': { backgroundImage: 'linear-gradient(to right, #fa0, #f00)' },
                    '100%': { backgroundImage: 'linear-gradient(to right, #f00, #f0f)' }
                },
                '@keyframes anim': {
                    from: {
                        margin: 0,
                        opacity: 1
                    },
                    to: {
                        margin: -15,
                        opacity: 0
                    }
                }
            }}
        />
    );

    return inputGlobalStyles;
}
