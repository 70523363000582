import { useEffect, useState } from 'react';
// Mui
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import useResponsive from 'hooks/useResponsive';
import useAuth from 'hooks/useAuth';
import { auraKey } from 'config/constant';

const Poker = () => {
    const isDesktop = useResponsive('up', 'sm');
    const { isLogined } = useAuth();
    const [link, setLink] = useState('');

    useEffect(() => {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken && isLogined) {
            setLink(`https://${isDesktop ? 'd2' : 'm2'}.fawk.app/#/splash-screen/${accessToken}|hattrick/${auraKey}`);
        } else {
            setLink(`https://${isDesktop ? 'd2' : 'm2'}.fawk.app/#/splash-screen`);
        }
    }, [isDesktop, isLogined]);

    return (
        <Box sx={{ pt: 2 }}>
            <Container maxWidth="xl" sx={{ px: 0 }}>
                <iframe id="poker" title="poker" width="100%" style={{ height: '85vh', border: 0 }} src={link} />
            </Container>
        </Box>
    );
};

export default Poker;
