// Mui
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
// config
import { spribeGameList } from 'config/constant';
// hooks
import useAuth from 'hooks/useAuth';
import useSettings from 'hooks/useSettings';
// utils

type IGame = {
    icon: string;
    title: string;
    game: string;
};

const GameList = () => {
    const { enqueueSnackbar } = useSnackbar();

    const { onChangeLoginModal } = useSettings();
    const { isLogined, user, accessToken } = useAuth();

    const { onChangeGameModal } = useSettings();

    const handleLaunch = async (game: IGame) => {
        try {
            if (accessToken && isLogined) {
                onChangeGameModal({
                    open: true,
                    title: 'Aviator',
                    // link: `https://spbav.com/aviator/login?token=${data.data.jwtToken}&from=https://bdgamings.com/spribe&userName=${data.data.userName}&userId=${data.data._id}`
                    link: `https://spbav.com/aviator/login?userId=${user._id}&userName=${user.username}&token=${accessToken}&currency=INR&operator=aviator&from=https://bdgamings.com/spribe`
                });
            }
        } catch (e) {
            enqueueSnackbar('Can not open game', { variant: 'error' });
        }
    };

    return (
        <Container maxWidth="xl">
            <Grid container spacing={2}>
                {spribeGameList.map((game: IGame, i: number) => (
                    <Grid item md={12 / 6} sm={12 / 4} xs={6} key={i}>
                        <Box
                            sx={{
                                flexGrow: 1,
                                borderRadius: 2,
                                overflow: 'hidden',
                                cursor: 'pointer',
                                position: 'relative'
                            }}
                        >
                            <Stack>
                                <div
                                    style={{
                                        paddingBottom: '60%',
                                        backgroundImage: `url(${game.icon})`,
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat'
                                    }}
                                />
                                <Stack sx={{ py: 2, px: 1, bgcolor: (theme) => theme.palette.background.paper }}>
                                    <Typography sx={{ fontSize: 12, opacity: 0.8, color: 'common.white' }}>
                                        Crash Game
                                    </Typography>
                                    <Stack direction="row" alignItems="center" justifyContent="space-between" mt={1}>
                                        <Typography sx={{ fontSize: 14, fontWeight: 700, color: 'common.white' }}>
                                            {game.title}
                                        </Typography>
                                        {/* <Icon icon="iconamoon:star-fill" color="white" width="18" height="18" /> */}
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                    top: 0,
                                    transition: 'opacity 0.5s',
                                    zIndex: 9,
                                    opacity: 0,
                                    bgcolor: '#535353b0',
                                    '&:hover': {
                                        opacity: 1
                                    }
                                }}
                            >
                                {isLogined ? (
                                    <Button onClick={() => handleLaunch(game)} variant="contained" color="primary">
                                        Play
                                    </Button>
                                ) : (
                                    <Button
                                        onClick={onChangeLoginModal}
                                        variant="contained"
                                        color="warning"
                                        sx={{ color: '#fff' }}
                                    >
                                        Login
                                    </Button>
                                )}
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default GameList;
