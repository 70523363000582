import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
// @mui
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// hooks
import useSettings from 'hooks/useSettings';
import { StyledDepositBtn, StyledPopover } from 'components/Styles';
import { useSnackbar } from 'components/snackbar';
import useAuth from 'hooks/useAuth';
import { useRouter } from 'hooks/use-router';
import useResponsive from 'hooks/useResponsive';
import { balanceRound } from 'utils/utils';

type listItemProps = {
    order: number;
    title: string;
    link: string;
    icon: any;
};

const UserPart = () => {
    const { onChangeLoginModal, onChangePasswordModal, config } = useSettings();
    const { enqueueSnackbar } = useSnackbar();
    const { user, isLogined, logout } = useAuth();
    const { balance } = useSelector((state: any) => state.auth);
    const router = useRouter();

    const isMobile = useResponsive('down', 'sm');

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [anchorBalanceEl, setAnchorBalanceEl] = useState<HTMLButtonElement | null>(null);
    const [open, setOpen] = useState(false);
    const [showBalance, setShowBalance] = useState(false);

    const dropList = useMemo(() => {
        if (config) {
            let array: listItemProps[] = [
                {
                    title: 'profile',
                    order: 0,
                    link: '/profile',
                    icon: <Icon icon="mingcute:user-4-fill" width="24" height="24" />
                },
                {
                    order: 3,
                    title: 'account statement',
                    link: '/reports/account-statement',
                    icon: <Icon icon="game-icons:receive-money" width="24" height="24" />
                },
                {
                    order: 4,
                    title: 'game report',
                    link: '/reports/profit-loss',
                    icon: <Icon icon="octicon:log-16" width="24" height="24" />
                },
                {
                    order: 6,
                    title: 'my bets',
                    link: '/reports/mybets',
                    icon: <Icon icon="mdi:book-alphabet" width="24" height="24" />
                },
                {
                    order: 7,
                    title: 'Chip Settings',
                    link: '/setting/stake',
                    icon: <Icon icon="mdi:poker-chip" width="24" height="24" />
                }
                // {
                //     order: 8,
                //     title: 'IP log',
                //     link: '/reports/login-history',
                //     icon: <Icon icon="solar:document-text-bold-duotone" width="24" height="24" />
                // }
            ];
            if (config.IsDepositAllow) {
                array.push({
                    order: 1,
                    title: 'deposit',
                    link: '/payment/deposit',
                    icon: <Icon icon="mingcute:bank-fill" width="24" height="24" />
                });
            }
            if (config.IsWithdrawalAllow) {
                array.push({
                    order: 2,
                    title: 'withdraw',
                    link: '/payment/withdraw',
                    icon: <Icon icon="maki:bank" width="24" height="24" />
                });
            }
            if (config.type === 'B2C') {
                array.push({
                    order: 5,
                    title: 'Bonus',
                    link: '/reports/bonus',
                    icon: <Icon icon="fa6-solid:gift" width="24" height="24" />
                });
                array.push({
                    order: 7,
                    title: 'Deposit report',
                    link: '/reports/deposit',
                    icon: <Icon icon="fa6-solid:money-bill-transfer" width="24" height="24" />
                });
                array.push({
                    order: 7,
                    title: 'Withdraw report',
                    link: '/reports/withdraw',
                    icon: <Icon icon="fa6-solid:money-bill-transfer" width="24" height="24" />
                });
            }
            return array.sort((a, b) => a.order - b.order);
        }
        return [];
    }, [config]);
    console.log(config);
    const anchorOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const anchorBalanceOpen = (event: any) => {
        if (showBalance) {
            setShowBalance(false);
            setAnchorBalanceEl(null);
        } else {
            setAnchorBalanceEl(event.currentTarget);
            setShowBalance(true);
        }
    };

    const handleBalanceClose = () => {
        setShowBalance(false);
    };

    const handlePassword = () => {
        setOpen(false);
        setAnchorEl(null);
        onChangePasswordModal(true);
    };

    const handleLogout = () => {
        try {
            setOpen(false);
            setAnchorEl(null);
            logout();
        } catch (error: any) {
            const message = typeof error === 'string' ? error : error.message;
            enqueueSnackbar(message, { variant: 'error' });
        }
    };

    const goRouter = (link: string) => {
        router.push(link);
        setOpen(false);
    };

    if (isLogined) {
        return (
            <Stack direction="row" alignItems="center" spacing={{ md: 1, xs: 2 }} sx={{ position: 'relative' }}>
                {isMobile ? (
                    <>
                        <Stack direction="row" alignItems="center" onClick={anchorBalanceOpen}>
                            <Stack alignItems="center" sx={{ cursor: 'pointer' }}>
                                <Typography
                                    sx={{
                                        color: 'text.secondary',
                                        opacity: 0.8,
                                        fontSize: { sm: 14, xs: 12 },
                                        fontWeight: 700
                                    }}
                                >
                                    Balance:
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: { sm: 14, xs: 12 },
                                        lineHeight: 1,
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    {`${balanceRound(balance.available)} ${user?.currency}`}
                                </Typography>
                            </Stack>
                        </Stack>
                        {config?.IsDepositAllow && (
                            <RouterLink to="/payment/deposit">
                                <StyledDepositBtn
                                    size="small"
                                    className="btn-deposit"
                                    color="success"
                                    variant="contained"
                                    startIcon={<Icon icon="noto:coin" color="gray" />}
                                >
                                    Deposit
                                </StyledDepositBtn>
                            </RouterLink>
                        )}
                    </>
                ) : (
                    <>
                        {config?.IsDepositAllow && (
                            <RouterLink to="/payment/deposit">
                                <StyledDepositBtn
                                    size="small"
                                    className="btn-deposit"
                                    color="success"
                                    variant="contained"
                                    startIcon={<Icon icon="noto:coin" color="gray" />}
                                >
                                    Deposit
                                </StyledDepositBtn>
                            </RouterLink>
                        )}
                        <Stack direction="row" alignItems="center" onClick={anchorBalanceOpen}>
                            <Stack alignItems="center" sx={{ cursor: 'pointer' }}>
                                <Typography
                                    sx={{
                                        color: 'text.secondary',
                                        opacity: 0.8,
                                        fontSize: { sm: 14, xs: 12 },
                                        fontWeight: 700
                                    }}
                                >
                                    Balance:
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: { sm: 14, xs: 12 },
                                        lineHeight: 1,
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    {`${balanceRound(balance.available)} ${user?.currency}`}
                                </Typography>
                            </Stack>
                        </Stack>
                    </>
                )}

                <StyledPopover
                    sx={{ '& .MuiPopover-paper': { bgcolor: 'background.paper' } }}
                    id="balance-menu-list"
                    open={showBalance}
                    anchorEl={anchorBalanceEl}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    onClose={handleBalanceClose}
                >
                    <Stack direction="row" justifyContent="space-between">
                        <Typography sx={{ fontSize: 14 }}>Main :</Typography>
                        <Typography sx={{ fontSize: 14 }}>{`${balanceRound(balance.main)} ${
                            user?.currency
                        }`}</Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography sx={{ fontSize: 14 }}>Available :</Typography>
                        <Typography sx={{ fontSize: 14 }}>
                            {`${balanceRound(balance.available)} ${user?.currency}`}
                        </Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography sx={{ fontSize: 14 }}>Exposure :</Typography>
                        <Typography sx={{ fontSize: 14 }}>
                            {`${balanceRound(balance.exposure)} ${user?.currency}`}
                        </Typography>
                    </Stack>
                    {user.accountType === 'B2C' && (
                        <Stack direction="row" justifyContent="space-between">
                            <Typography sx={{ fontSize: 14 }}>Bonus :</Typography>
                            <Typography sx={{ fontSize: 14 }}>
                                {`${balanceRound(balance?.bonus)} ${user?.currency}`}
                            </Typography>
                        </Stack>
                    )}
                    {!!balance.withdrawable && (
                        <Stack direction="row" justifyContent="space-between">
                            <Typography sx={{ fontSize: 14 }}>Withdrawable :</Typography>
                            <Typography sx={{ fontSize: 14 }}>
                                {`${balanceRound(balance?.withdrawable)} ${user?.currency}`}
                            </Typography>
                        </Stack>
                    )}
                    {!!balance.pending && (
                        <Stack direction="row" justifyContent="space-between">
                            <Typography sx={{ fontSize: 14 }}>Pending :</Typography>
                            <Typography sx={{ fontSize: 14 }}>
                                {`${balanceRound(balance.pending)} ${user?.currency}`}
                            </Typography>
                        </Stack>
                    )}
                </StyledPopover>
                <IconButton onClick={anchorOpen} sx={{ display: { sm: 'flex', xs: 'none' } }}>
                    <Icon icon="solar:user-bold" color="white" />
                </IconButton>
                <StyledPopover
                    sx={{ '& .MuiPopover-paper': { bgcolor: 'background.paper' } }}
                    id="user-menu-list"
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    onClose={handleClose}
                >
                    <Stack>
                        <List
                            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                            subheader={
                                <ListSubheader component="div" sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
                                    <Icon icon="mingcute:user-4-fill" color="white" width="24" height="24" />
                                    <Typography sx={{ fontSize: 14, fontWeight: 600, ml: 1, color: 'text.primary' }}>
                                        {user.username}
                                    </Typography>
                                </ListSubheader>
                            }
                        >
                            {dropList.map((item: listItemProps, index: number) => (
                                <ListItemButton
                                    // component={RouterLink}
                                    // to={item.link}
                                    onClick={() => goRouter(item.link)}
                                    key={index}
                                    sx={{
                                        minWidth: 200,
                                        '& .MuiListItemText-primary': {
                                            fontSize: 14,
                                            fontWeight: 600,
                                            color: 'text.secondary',
                                            textTransform: 'capitalize'
                                        },
                                        '& svg': {
                                            color: 'text.secondary'
                                        },
                                        '&:hover': {
                                            '& svg': {
                                                color: 'text.primary'
                                            },
                                            '& .MuiListItemText-primary': {
                                                color: 'text.primary'
                                            }
                                        }
                                    }}
                                >
                                    {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                                    <ListItemText primary={item.title} />
                                </ListItemButton>
                            ))}
                            <ListItemButton
                                onClick={handlePassword}
                                sx={{
                                    minWidth: 200,
                                    '& .MuiListItemText-primary': {
                                        fontSize: 14,
                                        fontWeight: 600,
                                        color: 'text.secondary',
                                        textTransform: 'capitalize'
                                    },
                                    '& svg': {
                                        color: 'text.secondary'
                                    },
                                    '&:hover': {
                                        '& svg': {
                                            color: 'text.primary'
                                        },
                                        '& .MuiListItemText-primary': {
                                            color: 'text.primary'
                                        }
                                    }
                                }}
                            >
                                <ListItemIcon>
                                    <Icon icon="solar:lock-password-bold-duotone" width="24" height="24" />
                                </ListItemIcon>
                                <ListItemText primary="Change Password" />
                            </ListItemButton>
                            <ListItemButton
                                onClick={handleLogout}
                                sx={{
                                    minWidth: 200,
                                    '& .MuiListItemText-primary': {
                                        fontSize: 14,
                                        fontWeight: 600,
                                        color: 'text.secondary',
                                        textTransform: 'capitalize'
                                    },
                                    '& svg': {
                                        color: 'text.secondary'
                                    },
                                    '&:hover': {
                                        '& svg': {
                                            color: 'text.primary'
                                        },
                                        '& .MuiListItemText-primary': {
                                            color: 'text.primary'
                                        }
                                    }
                                }}
                            >
                                <ListItemIcon>
                                    <Icon icon="solar:logout-3-bold" width="24" height="24" />
                                </ListItemIcon>
                                <ListItemText primary={'logout'} />
                            </ListItemButton>
                        </List>
                    </Stack>
                </StyledPopover>
            </Stack>
        );
    }

    return (
        <Stack direction="row" spacing={1}>
            <Button
                variant="contained"
                color="warning"
                sx={{
                    color: '#fff',
                    '& .MuiButton-startIcon': {
                        display: { xs: 'none', sm: 'flex' }
                    }
                }}
                startIcon={<Icon icon="solar:login-3-bold" color="white" />}
                onClick={onChangeLoginModal}
            >
                Login
            </Button>
            {config?.IsClientsSideRegistration && (
                <Button
                    component={RouterLink}
                    to="/auth/register"
                    variant="contained"
                    color="inherit"
                    sx={{
                        '& .MuiButton-startIcon': {
                            display: { xs: 'none', sm: 'flex' }
                        }
                    }}
                    startIcon={<Icon icon="mdi:register" />}
                >
                    SignUp
                </Button>
            )}
        </Stack>
    );
};

export default UserPart;
