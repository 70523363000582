// @mui
import { useTheme } from '@mui/material/styles';
import { GlobalStyles } from '@mui/material';

// ----------------------------------------------------------------------

export default function StyledNotistack() {
    const theme = useTheme();

    const isLight = theme.palette.mode === 'light';

    const inputGlobalStyles = (
        <GlobalStyles
            styles={{
                '#hattrick247': {
                    '.SnackbarContent-root': {
                        width: '100%',
                        position: 'relative',
                        padding: theme.spacing(1),
                        margin: theme.spacing(0.25, 0),
                        boxShadow: theme.customShadows.z24,
                        borderRadius: theme.shape.borderRadius,
                        borderWidth: 1,
                        borderStyle: 'solid',
                        color: isLight ? theme.palette.common.white : theme.palette.grey[800],
                        backgroundColor: isLight ? theme.palette.grey[900] : theme.palette.common.white,
                        '&.SnackbarItem-variantError': {
                            color: theme.palette.text.primary,
                            backgroundColor: theme.palette.error.darker,
                            borderColor: theme.palette.error.dark
                        },
                        '&.SnackbarItem-variantSuccess': {
                            color: theme.palette.text.primary,
                            backgroundColor: theme.palette.success.darker,
                            borderColor: theme.palette.success.dark
                        },
                        '&.SnackbarItem-variantWarning': {
                            color: theme.palette.text.primary,
                            backgroundColor: theme.palette.warning.darker,
                            borderColor: theme.palette.warning.dark
                        },
                        '&.SnackbarItem-variantInfo': {
                            color: theme.palette.text.primary,
                            backgroundColor: theme.palette.info.darker,
                            borderColor: theme.palette.info.dark
                        },
                        [theme.breakpoints.up('md')]: {
                            minWidth: 240
                        }
                    },
                    '.SnackbarItem-message': {
                        padding: '0 !important',
                        fontWeight: theme.typography.fontWeightMedium
                    },
                    '.SnackbarItem-action': {
                        marginRight: 0,
                        color: theme.palette.action.active,

                        '& svg': {
                            width: 20,
                            height: 20
                        }
                    }
                }
            }}
        />
    );

    return inputGlobalStyles;
}
