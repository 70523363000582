import { useMemo } from 'react';
import { Icon } from '@iconify/react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import IconButton from '@mui/material/IconButton';

import { headerList } from 'config/constant';
import useSettings from 'hooks/useSettings';
import UserPart from './UserPart';
import useAuth from 'hooks/useAuth';
import { HeaderItemProps } from 'types/sports';

const SportsHeader = () => {
    const { navbar, onChangeNav } = useSettings();
    const { user, isLogined } = useAuth();

    const allowedList = useMemo(() => {
        if (isLogined && user && user.permissions) {
            return headerList.filter((e: HeaderItemProps) => !(user.permissions[e.title] === false));
        }
        return headerList;
    }, [isLogined, user, headerList]);

    return (
        <Box
            component="header"
            sx={{
                top: 0,
                zIndex: 12,
                width: '100%',
                position: 'sticky',
                bgcolor: (theme) => theme.palette.background.default
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={0.5}
                sx={{
                    py: 0.3,
                    px: 0.5,
                    width: '100%',
                    bgcolor: '#284864'
                }}
            >
                <IconButton sx={{ p: 0 }} onClick={onChangeNav}>
                    {navbar === 'close' ? (
                        <Icon icon="mdi:menu-close" color="#ffab00" width="30" height="30" />
                    ) : (
                        <Icon icon="ic:twotone-menu-open" color="#ffab00" width="30" height="30" />
                    )}
                </IconButton>
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={0.3}
                    sx={{ overflow: 'auto', width: '100%', py: 0.3 }}
                >
                    {allowedList.map((item: { title: string; icon: string; link: string }, i: number) => (
                        <ButtonBase
                            key={i}
                            color="inherit"
                            component={RouterLink}
                            to={item.link}
                            sx={{
                                p: 1,
                                px: 3,
                                flexGrow: 1,
                                borderRadius: 1,
                                color: 'text.primary',
                                whiteSpace: 'nowrap',
                                bgcolor: 'background.paper',
                                '&:hover': {
                                    bgcolor: 'background.paper'
                                }
                            }}
                        >
                            <img src={item.icon} alt="item" style={{ width: 20 }} />
                            <Typography sx={{ fontWeight: 600, ml: 1 }}>{item.title}</Typography>
                        </ButtonBase>
                    ))}
                </Stack>
                <UserPart />
            </Stack>
        </Box>
    );
};

export default SportsHeader;
